import axios from 'axios';
import cheerio from 'cheerio';

const externalService = {
	getVesselInfo: async function(visit) {
		try {
			if (!visit.vesselname || !visit.vesselmmsi || !visit.vesselimo) {
				console.log('Not all parameters available (vessel, mmsi, imo)', visit.vesselname, visit.vesselmmsi, visit.vesselimo);
				return;
			}

			const vessel = visit.vesselname.replaceAll(' ', '-').toLowerCase();
			const mmsi = visit.vesselmmsi;
			const imo = visit.vesselimo;
			// https://www.myshiptracking.com/vessels/msc-amsterdam-mmsi-373598000-imo-9606338
			const url = `/myshiptracking/vessels/${vessel}-mmsi-${mmsi}-imo-${imo}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.log('Bad request', response);
				return;
			} else if (!response.data) {
				console.log('No response.data', response);
				return;
			}

			const $ = cheerio.load(response.data);
			const table = $("table");

			const header = $("h3.text-truncate");

			let longitude = table[2].children[1].children[1].children[3].children[0].data;
			let latitude = table[2].children[1].children[3].children[3].children[0].data;
			let speed = table[2].children[1].children[7].children[3].children[0].data;
			const destination = header[1].children[0].data;

			longitude = longitude && longitude.replace('°', '');
			latitude = latitude && latitude.replace('°', '');
			speed = speed && speed.replace(' Knots', '');

			return { imo, mmsi, longitude, latitude, speed, destination };
		} catch(err) {
			console.error(err);
		}
	},
};

export default externalService;
