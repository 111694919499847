<template>
	<div class="berthplanner-container">
		<berth-planner-toolbar :statusItems="statusItems" />
		<berth-planner-diagram
			v-if="cartoReady && stopsLoaded && quay"
			:firstWeekDay="firstWeekDay"
			:lastWeekDay="lastWeekDay"
			:dailyForecast="dailyForecast"
			:quay="quay"
			:stops="stops"
		/>
		<berth-planner-legend :items="legendItems" />
		<operations-countdown />
	</div>
</template>

<script>
import BerthPlannerContainerMixin from '@/mixins/BerthPlannerContainerMixin.js';

export default {
	mixins: [BerthPlannerContainerMixin],
	data() {
		return {
			modelName: 'berthplannerlite'
		};
	},
	methods: {
		isStopEtcMinusXMinutesLowerThanNow() {
			return false;
		}
	}
};
</script>
