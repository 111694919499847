<template>
<div class="bp_vessel bp_vessel_container" :style="vessel.style" @click="$refs.berthPlannerBerthBlockForm.visible = vessel.stop.status === 'BERTH_BLOCK' && $store.getters.editStopBlock">
	<div class="bp_vessel_top-left"></div>
	<div class="bp_vessel_top-middle"></div>
	<div v-if="$store.getters.isBerthPlannerPro && vessel.showAlertsTop" class="bp_vessel_top-right">
		<v-badge :content="alerts" :value="alerts" color="orange" overlap>
			<v-icon v-show="vessel.stop.delayed || vessel.stop.lessthantwentyfourtoeta || vessel.stop.ninetyminstoetc
				|| vessel.stop.sixtyminstoetc || vessel.stop.thirtyminstoetc"
				 color="red darken-2">fas fa-exclamation-circle</v-icon>
		</v-badge>
	</div>
	<div class="bp_vessel_bottom-left"></div>
	<div class="bp_vessel_bottom-middle" >
		<v-tooltip bottom v-if="vesselimo">
			<template v-slot:activator="{ on, attrs }">
				<div v-on="on" v-bind="attrs">
					<b v-show="vessel.showVesselImo" data-test="vesselimo">{{ vesselimo }}</b>
					<b v-show="vessel.showVesselName" data-test="vesselname">{{ vesselnameportcallnumber }}</b>
					<br v-show="vessel.showVesselName">
					<span v-show="vessel.showVesselDates" data-test="vesseldates">{{ vesseldates }}</span>
					<br v-show="vessel.showVesselDates">
					<span v-if="vessel.showVesselPhase" data-test="vesselphase">{{ vessel.stop.phase }}</span>
				</div>
			</template>
			<berth-planner-vessel-tooltip :stop="vessel.stop" v-show="vesselimo"/>
		</v-tooltip>
		<div v-else-if="vessel.stop.status === 'BERTH_BLOCK'">
			<b v-show="vessel.showVesselName" data-test="vesselname">{{ vesselnameportcallnumber }}</b>
			<br v-show="vessel.showVesselName">
			<span v-show="vessel.showVesselDates" data-test="vesseldates">{{ vesseldates }}</span>
			<br v-show="vessel.showVesselDates">
		</div>
	</div>
	<div v-if="$store.getters.isBerthPlannerPro && !vessel.showAlertsTop" class="bp_vessel_bottom-right">
		<v-badge :content="alerts" :value="alerts" color="orange" overlap>
			<v-icon v-if="vessel.stop.delayed || vessel.stop.lessthantwentyfourtoeta || vessel.stop.ninetyminstoetc
				|| vessel.stop.sixtyminstoetc || vessel.stop.thirtyminstoetc"
				 color="red darken-2">fas fa-exclamation-circle</v-icon>
		</v-badge>
	</div>
	<berth-planner-berth-block-form ref="berthPlannerBerthBlockForm" :vessel="vessel.stop"/>
</div>
</template>

<script>
import BerthPlannerVesselTooltip from './BerthPlannerVesselTooltip.vue';
import BerthPlannerBerthBlockForm from './BerthPlannerBerthBlockForm.vue';
import BerthPlannerMixin from '@/mixins/BerthPlannerMixin';

export default {
	components: { BerthPlannerVesselTooltip, BerthPlannerBerthBlockForm },
	mixins: [BerthPlannerMixin],
	props: {
		vessel: {
			type: Object,
			required: true
		}
	},
	computed: {
		alerts() {
			if (this.vessel && this.vessel.stop) {
				let alerts = 0;
				if (this.vessel.stop.delayed) {
					alerts++;
				}
				if (this.vessel.stop.lessthantwentyfourtoeta) {
					alerts++;
				}
				if (this.vessel.stop.ninetyminstoetc) {
					alerts++;
				} else if (this.vessel.stop.sixtyminstoetc) {
					alerts++;
				} else if (this.vessel.stop.thirtyminstoetc) {
					alerts++;
				}
				return alerts > 1 ? alerts : 0;
			}
			return undefined;
		},
		vesselimo() {
			if (this.vessel && this.vessel.stop && this.vessel.stop.vesselimo) {
				return this.vessel.stop.vesselimo;
			}
			return '';
		},
		vesselnameportcallnumber() {
			if (this.vessel && this.vessel.stop) {
				if (this.vessel.stop.vesselname && this.vessel.stop.portcallnumber) {
					return `${this.vessel.stop.vesselname} (${this.vessel.stop.portcallnumber})`;
				}
				return this.vessel.stop.vesselname;
			}
			return '';
		},
		vesseldates() {
			if (this.vessel && this.vessel.stop) {
				return `${this.dateFormat(this.vessel.stop.etaplannerlocal, this.timeFormat)} / ${this.dateFormat(this.vessel.stop.etdplannerlocal, this.timeFormat)}`;
			}
			return '';
		}
	}
}
</script>
