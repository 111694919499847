import { Fill, Style, Stroke, Circle } from 'ol/style';
import store from '@/store/store';

const pointStyleFunction = function (feature, resolution) {
	return new Style({
		image: new Circle({
			radius: 6, // Radio del círculo del punto
			fill: createFillStyle(feature),
			stroke: new Stroke({
				color: createStrokeStyle(feature),
				width: 2 // Ancho del borde del círculo
			})
		})
	});
};

const createStrokeStyle = function (feature) {
	const thematicColorPalete = store.getters.stopsStatusValues;
	if (!feature.get('featureVisible')) {
		return 'rgba(0, 0, 0, 0)';
	}
	if (feature.get('modified')) {
		return '#CBC000';
	}
	return thematicColorPalete && feature.get('statusid') ? thematicColorPalete[feature.get('statusid')].coloroutline : '#000000'; // Color de relleno del círculo
};

const createFillStyle = function (feature) {
	const thematicColorPalete = store.getters.stopsStatusValues;
	if (!feature.get('featureVisible')) {
		return new Fill({
			color: 'rgba(0, 0, 0, 0)'
		});
	}
	if (feature.get('modified')) {
		return new Fill({
			color: '#FFF554'
		});
	}
	return new Fill({
		color: thematicColorPalete && feature.get('statusid') ? thematicColorPalete[feature.get('statusid')].color : '#000000' // Color de relleno del círculo
	});
};

export default pointStyleFunction;
