<template>
	<v-dialog v-model="visible" width="60%" scrollable>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.weatherInfo') }}</span>
				<v-spacer />
				<v-icon @click="visible = false">fas fa-times</v-icon>
			</v-card-title>
			<v-divider />
			<v-card-actions class="d-flex justify-center">
				<v-data-table :headers="headers" :items="items" hide-default-footer disable-sort class="elevation-1">
					<template v-slot:header.hour1="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour4="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour7="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour10="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour13="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour16="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour19="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.hour22="{ header }">
						<span style="color: #2d78ef"> {{ header.text }}</span>
					</template>
					<template v-slot:header.name="{ header }">
						<span class="font-weight-bold"> {{ header.text }}</span>
					</template>
					<template v-slot:item.name="{ item }">
						<span class="font-weight-bold"> {{ $t(item.name) }}</span>
					</template></v-data-table
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import WeatherService from '@/services/weather.service.js';
import { get12Hours } from '@amcharts/amcharts4/.internal/core/utils/Utils';

export default {
	props: {},
	data() {
		return {
			visible: false,
			hourlyForecast: {},
			headers: [],

			items: [],
			berthPlannerDate: new Date()
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.mountHeaders();
		this.getHourlyForecastFor4Days();
		this.$puiEvents.$on('berthplanner-toolbar_dateSelected', (dateSelected) => {
			this.berthPlannerDate = dateSelected;
			this.getHourlyForecastFor4Days();
		});
		//this.pruebaMapa();
	},
	methods: {
		mountHeaders() {
			this.headers.push({
				text: this.$t('berthplanner.hours'),
				value: 'name'
			});
			for (let i = 1; i <= 23; i = i + 3) {
				this.headers.push({
					text: i < 10 ? '0' + i + ':00' : i + ':00',
					value: 'hour' + i
				});
			}
		},
		mountItems() {
			this.items = [];
			let item = {};
			let item2 = {};

			this.hourlyForecast.forEach((element) => {
				var date = new Date(element.dt * 1000);
				if (date.getDay() == this.berthPlannerDate.getDay()) {
					var vari = 'hour' + date.getHours();
					item[vari] = Math.round(element.wind.speed * 10) / 10 + ' m/s';
					item.name = 'Velocidad';
					item2[vari] = element.wind.deg + 'º (' + WeatherService.getWindDirectionFromWindDegrees(element.wind.deg) + ')';
					item2.name = 'Dirección';
				}
			});
			this.items.push(item);
			this.items.push(item2);
		},
		async getHourlyForecastFor4Days() {
			const portId = window.localStorage.workingPortsId;
			if (portId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: portId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, async (response) => {
					if (response.data.data[0].latitude && response.data.data[0].longitude) {
						this.portLatitude = response.data.data[0].latitude;
						this.portLongitude = response.data.data[0].longitude;
						this.hourlyForecast = await WeatherService.getHourlyForecastFor5Days(this.portLatitude, this.portLongitude);
						console.log(this.hourlyForecast);
						if (this.hourlyForecast) {
							this.mountItems();
						}
					}
				});
			}
		},
		pruebaMapa() {
			const portId = window.localStorage.workingPortsId;
			if (portId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: portId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, async (response) => {
					if (response.data.data[0].latitude && response.data.data[0].longitude) {
						this.portLatitude = response.data.data[0].latitude;
						this.portLongitude = response.data.data[0].longitude;
						var data = await WeatherService.getWindMap(this.portLatitude, this.portLongitude);
						console.log(data);
					}
				});
			}
		}
	}
};
</script>
