<template>
	<v-progress-linear v-model="progress" class="mapupdatecontrol"></v-progress-linear>
</template>

<script>
export default {
	name: 'mapupdatecontrol',

	data() {
		return {
			isStopProgress: false,
			progress: 0,
			refreshSession: 0,
			interval: null,
			tempoRefresh: this.defaultTempoRefresh,
			defaultTempoRefresh: 60
		};
	},

	methods: {
		startProgressBar() {
			this.isStopProgress = false;
			// start next interval
			this.interval = !this.interval ? setInterval(() => this.tick(), 1000) : this.interval;
		},

		stopProgressBar() {
			if (this.interval) {
				this.isStopProgress = true;
				this.progress = 0;
				clearInterval(this.interval);
				this.interval = null;
			}
		},

		pauseProgressBar() {
			if (this.interval) {
				clearInterval(this.interval);
				this.interval = null;
			}
		},

		resumeProgressBar() {
			this.interval = !this.interval ? setInterval(() => this.tick(), 1000) : this.interval;
		},

		resetProgressValue() {
			this.progress = 0;
		},

		getTempoRefreshAuthority() {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'TEMPO_REFRESH_MAP'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: window.localStorage.getItem('workingPortAuthorityId')
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.lenght > 0) {
					this.tempoRefresh = response.data.data[0].value == null ? this.defaultTempoRefresh : response.data.data[0].value;
				} else {
					this.tempoRefresh = this.defaultTempoRefresh;
				}
			});
		},

		keepSession() {
			this.$puiRequests.getRequest('/login/keepSessionActive');
		},

		tick() {
			this.progress += this.delta;

			if (this.progress <= 100) {
				return;
			}

			this.progress = 0;
			this.refreshSession += 1;

			this.$puiEvents.$emit('map:updated');

			if (this.refreshSession % 5 !== 0) {
				return;
			}

			this.refreshSession = 0;
			this.keepSession();
		},

		getPageVisibilityParams() {
			if (typeof document.hidden !== 'undefined') {
				// Opera 12.10 and Firefox 18 and later support
				return {
					hidden: 'hidden',
					visibilityChange: 'visibilitychange'
				};
			} else if (typeof document.msHidden !== 'undefined') {
				return {
					hidden: 'msHidden',
					visibilityChange: 'msvisibilitychange'
				};
			} else if (typeof document.webkitHidden !== 'undefined') {
				return {
					hidden: 'webkitHidden',
					visibilityChange: 'webkitvisibilitychange'
				};
			}
		},

		handleVisibilityChange() {
			const { hidden } = this.getPageVisibilityParams();

			if (document[hidden]) {
				this.pauseProgressBar();
				return;
			}

			if (!this.isStopProgress) {
				this.resumeProgressBar();
			}
		}
	},
	computed: {
		delta() {
			return 100 / this.tempoRefresh;
		}
	},
	created() {
		this.getTempoRefreshAuthority();
		this.startProgressBar();

		const { visibilityChange } = this.getPageVisibilityParams();

		document.addEventListener(visibilityChange, this.handleVisibilityChange);

		this.$puiEvents.$on('map:refreshprogress', this.resetProgressValue);
		this.$puiEvents.$on('map:stopprogress', this.stopProgressBar);
		this.$puiEvents.$on('map:startprogress', this.startProgressBar);
	},
	destroyed() {
		clearInterval(this.interval);

		const { visibilityChange } = this.getPageVisibilityParams();

		document.removeEventListener(visibilityChange, this.handleVisibilityChange);

		this.$puiEvents.$off('map:refreshprogress', this.resetProgressValue);
		this.$puiEvents.$off('map:stopprogress', this.stopProgressBar);
		this.$puiEvents.$off('map:startprogress', this.startProgressBar);
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';
.mapupdatecontrol {
	width: 100%;
	height: 2px !important;
	margin: 0px !important;
	position: absolute;
	top: 0px;
	z-index: 11;
	& >>> .v-progress-linear__bar {
		background-color: rgba(255, 255, 255, 0);
	}
}
</style>
