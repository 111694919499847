import mapUtils from '@/components/operations/map/mapUtils';
export default {
	methods: {
		/**
		 * Extension AIS event extended data
		 */
		drawTrackEventPoints(aisTrackEventPointFeatureCreator, aisTrackEventVesselLineFeatureCreator, vistrack, mmsi, imo) {
			if (vistrack.visWayEvents) {
				this.getAisEventExtendedDataAndProcess(aisTrackEventPointFeatureCreator, vistrack, mmsi, imo);
			}
			if (vistrack.visTrack || vistrack.visPosAis) {
				this.getVesselLineAndProcess(aisTrackEventVesselLineFeatureCreator, vistrack, mmsi, imo);
			}
		},
		async getAisEventExtendedDataAndProcess(aisTrackEventPointFeatureCreator, vistrack, mmsi, imo) {
			const aisextended = await this.getAisEventExtended(vistrack.dateFrom, vistrack.dateTo, mmsi, imo);
			if (aisextended.length > 0) {
				// Send the event legend
				const items = [];
				aisextended.map((item, index) => {
					const i = index + 1;
					items.push({
						index: i,
						id: item.ideventtype,
						color: item.colorhex,
						coloroutline: '#FFFFFF',
						text: item.eventtypedescription,
						geomdesc: item.geomdesc,
						shape: 'point',
						coordinates: mapUtils.projectCoordinates(item.aislon, item.aislat)
					});
				});
				this.$puiEvents.$emit('track:updateeventlegend', items);
				aisTrackEventPointFeatureCreator._processEventPointTrack(aisextended);
			} else {
				this.$puiEvents.$emit('track:updateeventlegend', []);
				this.$puiNotify.info(this.$puiI18n.t('operations.infomessages.noEventsShow'));
			}
		},

		async getVesselLineAndProcess(aisTrackEventVesselLineFeatureCreator, vistrack, mmsi, imo) {
			const aisvesselline = await this.getAisVesselLine(vistrack.dateFrom, vistrack.dateTo, mmsi, imo);
			if (aisvesselline.length > 1) {
				// Necesitamos dos puntos minimos para generar una linea
				aisTrackEventVesselLineFeatureCreator._processEventVesselLineTrack(aisvesselline, vistrack);
			} else {
				this.$puiNotify.info(this.$puiI18n.t('operations.infomessages.noTrackToShow'));
			}
		},

		async getAisEventExtended(dateFrom, dateTo, mmsi, imo) {
			const getAisEventExtendedBody = {
				model: 'logaiseventextendedmap',
				page: 1,
				rows: null,
				columns: [
					'vessel_name',
					'vessel_mmsi',
					'ais_update_date',
					'ais_speed',
					'ais_lon',
					'ais_lat',
					'geom_desc',
					'id_event_type',
					'event_type_description',
					'color_hex'
				],
				filter: {
					groupOp: 'and',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'ais_update_date', op: 'ge', data: dateFrom },
								{ field: 'ais_update_date', op: 'le', data: dateTo }
							]
						},
						{
							groupOp: 'or',
							rules: [
								{ field: 'vessel_mmsi', op: 'eq', mmsi },
								{ field: 'vessel_imo', op: 'eq', data: imo }
							]
						}
					]
				}
			};

			return this.$puiRequests.postRequest(
				'/puisearch',
				getAisEventExtendedBody,
				(response) => {
					return response.data.data;
				},
				{}
			);
		},

		async getAisVesselLine(dateFrom, dateTo, mmsi, imo) {
			const getAisEventExtendedBody = {
				model: 'mvesselline',
				page: 1,
				rows: null,
				columns: ['ais_mmsi', 'ais_update', 'ais_speed', 'ais_lon', 'ais_lat', 'ais_shipname'],
				filter: {
					groupOp: 'and',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'ais_update', op: 'ge', data: dateFrom != null ? new Date(dateFrom).getTime() : dateFrom }, // Date is in Epoch
								{ field: 'ais_update', op: 'le', data: dateTo != null ? new Date(dateTo).getTime() : dateTo } // Date is in Epoch
							]
						},
						{
							groupOp: 'or',
							rules: [
								{ field: 'ais_mmsi', op: 'eq', mmsi },
								{ field: 'ais_imo', op: 'eq', data: imo }
							]
						}
					]
				}
			};

			return this.$puiRequests.postRequest(
				'/puisearch',
				getAisEventExtendedBody,
				(response) => {
					return response.data.data;
				},
				{}
			);
		}
	}
};
