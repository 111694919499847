import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

/** Constants */
import { STOP_INSTANT_LAYER_ID } from '@/components/operations/map/constants/layers';
import { SETTINGS_PANEL_ID, INSTANT_PAST_PANEL_ID, INSTANT_FUTURE_PANEL_ID, REQUESTEDS_PANEL_ID, PLANNING_PANEL_ID } from '@/lib/variables/panels';
import { TYPE_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

/** Utils */
import mapFeatureStyleUtils from '@/components/operations/map/mapFeatureStyleUtils';

/** 3rd-party */
import { Point, Polygon } from 'ol/geom';

export default {
	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},

	computed: {
		stopsInstantPastOpts() {
			this.userQuaysId = this.$store.getters.getUserQuaysId;
			const basic = {
				groupOp: 'and',
				groups: [],
				rules: [
					{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
					{ field: 'berth_id', op: 'in', data: this.userQuaysId },
					{ field: 'statusid', op: 'in', data: this.pastStatusCode }
				]
			};
			const atd = {
				groupOp: 'or',
				groups: [],
				rules: [
					{ field: 'atd', op: 'ge', data: this.$store.state.mappanelperiods.fecIniPastpanel },
					{ field: 'atd', op: 'nu' }
				]
			};
			const ata = {
				groupOp: 'or',
				groups: [],
				rules: [
					{ field: 'ata', op: 'le', data: this.$store.state.mappanelperiods.fecEndPastpanel },
					{ field: 'ata', op: 'nu' }
				]
			};
			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [basic, atd, ata],
					rules: []
				}
			};
			return opts;
		},
		stopsInstantFutureOpts() {
			this.userQuaysId = this.$store.getters.getUserQuaysId;
			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
						{ field: 'berth_id', op: 'in', data: this.userQuaysId },
						{ field: 'statusid', op: 'in', data: this.futureStatusCode },
						{ field: 'etd', op: 'ge', data: this.$store.state.mappanelperiods.fecIniFuturepanel },
						{ field: 'etd', op: 'le', data: this.$store.state.mappanelperiods.fecEndFuturepanel }
					]
				}
			};
			return opts;
		},
		stopsInstantRequestedOpts() {
			this.userQuaysId = this.$store.getters.getUserQuaysId;

			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'or',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['PL', 'RQ', 'AC', 'PA'] },
								{ field: 'etd', op: 'ge', data: this.$store.state.mappanelperiods.fecIniRequestedspanel },
								{ field: 'eta', op: 'le', data: this.$store.state.mappanelperiods.fecEndRequestedspanel }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['AU'] },
								{ field: 'etdauth', op: 'ge', data: this.$store.state.mappanelperiods.fecIniRequestedspanel },
								{ field: 'etaauth', op: 'le', data: this.$store.state.mappanelperiods.fecEndRequestedspanel }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['IN'] },
								{ field: 'etdauth', op: 'ge', data: this.$store.state.mappanelperiods.fecIniRequestedspanel },
								{ field: 'ata', op: 'le', data: this.$store.state.mappanelperiods.fecEndRequestedspanel }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['IN'] },
								{ field: 'etdauth', op: 'nu' },
								{ field: 'ata', op: 'le', data: this.$store.state.mappanelperiods.fecEndRequestedspanel }
							]
						}
					]
				}
			};
			return opts;
		},
		stopsInstantPlanningOpts() {
			this.userQuaysId = this.$store.getters.getUserQuaysId;
			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'or',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['PL', 'RQ', 'AC', 'PA'] },
								{ field: 'etd', op: 'ge', data: this.$store.state.mappanelperiods.fecIniPlanningpanel },
								{ field: 'eta', op: 'le', data: this.$store.state.mappanelperiods.fecEndPlanningpanel }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['AU'] },
								{ field: 'etdauth', op: 'ge', data: this.$store.state.mappanelperiods.fecIniPlanningpanel },
								{ field: 'etaauth', op: 'le', data: this.$store.state.mappanelperiods.fecEndPlanningpanel }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['IN'] },
								{ field: 'etdauth', op: 'ge', data: this.$store.state.mappanelperiods.fecIniPlanningpanel },
								{ field: 'ata', op: 'le', data: this.$store.state.mappanelperiods.fecEndPlanningpanel }
							]
						}
					]
				}
			};
			return opts;
		},
		isTypeVisualization() {
			return this.$store.getters.getMapPeriodsStyleVisualization == TYPE_VIS;
		},
		pastStatusCode() {
			return this.$store.getters.getPastStatusCode;
		},

		futureStatusCode() {
			return this.$store.getters.getFutureStatusCode;
		},

		requestedsStatusCode() {
			return this.$store.getters.getRequestedsStatusCode;
		},

		planningStatusCode() {
			return this.$store.getters.getPlanningStatusCode;
		},

		requestedsViewStatusCode() {
			return this.$store.getters.getRequestedsViewStatusMapCode;
		},

		planningViewStatusCode() {
			return this.$store.getters.getPlanningViewStatusMapCode;
		},

		activePanelOptions() {
			return this.$store.getters.activePanelOptionsPeriods;
		},
		searchingTextPastPanel() {
			return this.$store.state.mappanelperiods.searchingtextpastpanel;
		},
		searchingTextFuturePanel() {
			return this.$store.state.mappanelperiods.searchingtextfuturepanel;
		},
		searchingTextRequestedsPanel() {
			return this.$store.state.mappanelperiods.searchingtextrequestedspanel;
		},
		searchingTextPlanningPanel() {
			return this.$store.state.mappanelperiods.searchingtextplanningpanel;
		}
	},

	methods: {
		getStopsInstantRequestedOptsSelected(fecini, fecend, idstop) {
			this.userQuaysId = this.$store.getters.getUserQuaysId;

			const opts = {
				model: 'stopoperations',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'or',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['PL', 'RQ', 'AC', 'PA'] },
								{ field: 'etd', op: 'ge', data: fecini },
								{ field: 'eta', op: 'le', data: fecend }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['AU'] },
								{ field: 'etdauth', op: 'ge', data: fecini },
								{ field: 'etaauth', op: 'le', data: fecend }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['IN'] },
								{ field: 'etdauth', op: 'ge', data: fecini },
								{ field: 'ata', op: 'le', data: fecend }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
								{ field: 'berth_id', op: 'in', data: this.userQuaysId },
								{ field: 'statusid', op: 'in', data: ['IN'] },
								{ field: 'etdauth', op: 'nu' },
								{ field: 'ata', op: 'le', data: fecend }
							]
						},
						{
							groupOp: 'and',
							rules: [{ field: 'id', op: 'eq', data: idstop }]
						}
					]
				}
			};
			return opts;
		},
		getProgressPanelActive(activePanel, activePanelOptions) {
			const progressPanelActive = {
				[SETTINGS_PANEL_ID]: {
					[INSTANT_PAST_PANEL_ID]: true,
					[INSTANT_FUTURE_PANEL_ID]: true,
					[REQUESTEDS_PANEL_ID]: false,
					[PLANNING_PANEL_ID]: false
				},
				[INSTANT_PAST_PANEL_ID]: true,
				[INSTANT_FUTURE_PANEL_ID]: true,
				[REQUESTEDS_PANEL_ID]: false,
				[PLANNING_PANEL_ID]: false
			};

			const instantPanel = activePanelOptions ? activePanelOptions.instantPanel : null;
			if (activePanelOptions && activePanelOptions.panelId.includes('::')) {
				return progressPanelActive[instantPanel];
			}
			return activePanelOptions ? progressPanelActive[activePanel][instantPanel] : progressPanelActive[activePanel];
		},
		getStopInstantOpts(activePanel, activePanelOptions) {
			const stopInstantOpts = {
				[SETTINGS_PANEL_ID]: {
					[INSTANT_PAST_PANEL_ID]: this.stopsInstantPastOpts,
					[INSTANT_FUTURE_PANEL_ID]: this.stopsInstantFutureOpts,
					[REQUESTEDS_PANEL_ID]: this.stopsInstantRequestedOpts,
					[PLANNING_PANEL_ID]: this.stopsInstantPlanningOpts
				},
				[INSTANT_PAST_PANEL_ID]: this.stopsInstantPastOpts,
				[INSTANT_FUTURE_PANEL_ID]: this.stopsInstantFutureOpts,
				[REQUESTEDS_PANEL_ID]: this.stopsInstantRequestedOpts,
				[PLANNING_PANEL_ID]: this.stopsInstantPlanningOpts
			};

			const instantPanel = activePanelOptions ? activePanelOptions.instantPanel : null;
			if (activePanelOptions && activePanelOptions.panelId.includes('::')) {
				return stopInstantOpts[instantPanel] || '';
			}
			return activePanelOptions ? stopInstantOpts[activePanel][instantPanel] || '' : stopInstantOpts[activePanel] || '';
		},
		getSearchTextPanel(activePanel, activePanelOptions) {
			const searchTextMap = {
				[SETTINGS_PANEL_ID]: {
					[INSTANT_PAST_PANEL_ID]: this.searchingTextPastPanel,
					[INSTANT_FUTURE_PANEL_ID]: this.searchingTextFuturePanel,
					[REQUESTEDS_PANEL_ID]: this.searchingTextRequestedsPanel,
					[PLANNING_PANEL_ID]: this.searchingTextPlanningPanel
				},
				[INSTANT_PAST_PANEL_ID]: this.searchingTextPastPanel,
				[INSTANT_FUTURE_PANEL_ID]: this.searchingTextFuturePanel,
				[REQUESTEDS_PANEL_ID]: this.searchingTextRequestedsPanel,
				[PLANNING_PANEL_ID]: this.searchingTextPlanningPanel
			};

			const instantPanel = activePanelOptions ? activePanelOptions.instantPanel : null;
			if (activePanelOptions && activePanelOptions.panelId.includes('::')) {
				return searchTextMap[instantPanel] || '';
			}
			return activePanelOptions ? searchTextMap[activePanel][instantPanel] || '' : searchTextMap[activePanel] || '';
		},

		getStatusCodes(activePanel, activePanelOptions) {
			const panelToStatusCodeMap = {
				[SETTINGS_PANEL_ID]: {
					[INSTANT_PAST_PANEL_ID]: this.pastStatusCode,
					[INSTANT_FUTURE_PANEL_ID]: this.futureStatusCode,
					[REQUESTEDS_PANEL_ID]: this.requestedsStatusCode,
					[PLANNING_PANEL_ID]: this.planningStatusCode
				},
				[INSTANT_PAST_PANEL_ID]: this.pastStatusCode,
				[INSTANT_FUTURE_PANEL_ID]: this.futureStatusCode,
				[REQUESTEDS_PANEL_ID]: this.requestedsStatusCode,
				[PLANNING_PANEL_ID]: this.planningStatusCode
			};
			const instantPanel = activePanelOptions ? activePanelOptions.instantPanel : null;

			if (activePanelOptions && activePanelOptions.panelId.includes('::')) {
				return panelToStatusCodeMap[instantPanel] || [];
			}

			return activePanelOptions ? panelToStatusCodeMap[activePanel][instantPanel] || [] : panelToStatusCodeMap[activePanel] || [];
		},

		getViewCodes(activePanel, activePanelOptions) {
			const viewStatusMap = {
				[SETTINGS_PANEL_ID]: {
					[INSTANT_PAST_PANEL_ID]: null,
					[INSTANT_FUTURE_PANEL_ID]: null,
					[REQUESTEDS_PANEL_ID]: this.requestedsViewStatusCode,
					[PLANNING_PANEL_ID]: this.planningViewStatusCode
				},
				[INSTANT_PAST_PANEL_ID]: null,
				[INSTANT_FUTURE_PANEL_ID]: null,
				[REQUESTEDS_PANEL_ID]: this.requestedsViewStatusCode,
				[PLANNING_PANEL_ID]: this.planningViewStatusCode
			};

			const instantPanel = activePanelOptions ? activePanelOptions.instantPanel : null;
			if (activePanelOptions && activePanelOptions.panelId.includes('::')) {
				return viewStatusMap[instantPanel] || [];
			}

			return activePanelOptions ? viewStatusMap[activePanel][instantPanel] || [] : viewStatusMap[activePanel] || [];
		},

		changeOpacityByPanel(activePanel, activePanelOptions) {
			const statusCodes = this.getStatusCodes(activePanel, activePanelOptions);
			const viewCodes = this.getViewCodes(activePanel, activePanelOptions);
			this.changeInstantLayerOpacityFeatureByStatusid(statusCodes, viewCodes, this.searchingTextPastPanel);
		},

		changeInstantLayerOpacityFeatureByStatusid(statusCodes, viewCodes = null, searchingtext = '') {
			var self = this;
			ol2map.getLayerById(STOP_INSTANT_LAYER_ID) &&
				ol2map.getLayerById(STOP_INSTANT_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_INSTANT_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						const vesselName = feature.getProperties().vesselname.toLowerCase();
						const statusId = feature.getProperties().statusid;

						const isVesselNameMatch = vesselName.includes(searchingtext.toLowerCase());
						const isStatusMatch = statusCodes.includes(statusId) || (viewCodes && viewCodes.includes(statusId));
						feature.set('featureVisible', isVesselNameMatch && isStatusMatch);
						feature.set('clickable', statusCodes.includes(statusId) ? true : false);

						const resolution = ol2map.getMapInstance().getView().getResolution();
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapPeriodsStyleVisualization
						);
						feature.changed();
					});
		},
		async getStops(bodyRequest, idLayer) {
			const getAllStopsBody = {
				model: 'stopoperations',
				page: 1,
				rows: null
			};

			const stops = [];

			return this.$puiRequests.postRequest(
				'/puisearch',
				bodyRequest || getAllStopsBody,
				(response) => {
					for (let i = 0, stopsLength = response.data.data.length; i < stopsLength; i++) {
						const stop = JSON.parse(JSON.stringify(response.data.data[i]));
						stop.idLayer = idLayer;
						stop.isAis = false;
						stop.observations = '';
						stop.entrydate = null;
						stop.destinationdate = null;

						// Gestionamos si es un Pilot o Tug
						stop.isPilot = stop.shiptypeais ? stop.shiptypeais.includes('50') : false;
						const tugValues = ['31', '32', '52'];
						const shiptypeaisArray = stop.shiptypeais ? stop.shiptypeais.split(';') : null;
						stop.isTug = shiptypeaisArray ? tugValues.some((valor) => shiptypeaisArray.includes(valor)) : false;
						stops.push(stop);
					}
					return stops;
				},
				{}
			);
		},

		cleanStopsToProcess(stopsToclean) {
			const cleanFeatures = stopsToclean.map((objeto) => {
				// Copia el objeto original excluyendo las propiedades, puntoAtraque, geometry, polyBounds, alfa, calculadas
				const { puntoAtraque, geometry, polyBounds, alfa, ptoMedio, distAbarloar, ...nuevoObjeto } = objeto;
				return nuevoObjeto;
			});
			return cleanFeatures;
		},

		orderStopsBeforeProcess(stops) {
			if (stops.length > 1) {
				stops.sort((a, b) => {
					// Si los statusid son iguales, ordenar por eta y ata
					if (a.statusid === 'IN' && b.statusid !== 'IN') {
						// Si a es 'IN' y b no lo es, comparar fecha ATA de a y fecha ETA de b
						return new Date(a.ata) - new Date(b.eta);
					} else if (a.statusid !== 'IN' && b.statusid === 'IN') {
						// Si a no es 'IN' y b lo es, comparar fecha ETA de a y fecha ATA de b
						return new Date(a.eta) - new Date(b.ata);
					} else {
						// Si los dos son 'IN', comparar por ata; Si los dos son diferentes de 'IN', comparar por eta
						return new Date(a.statusid === 'IN' ? a.ata : a.eta) - new Date(b.statusid === 'IN' ? b.ata : b.eta);
					}
				});
			}

			return stops;
		}
	}
};
