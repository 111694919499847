<template>
	<v-card>
		<v-container>
			<v-row align="center">
				<v-col cols="10">
					<span class="operations-map__title"
						>{{ vesselimonameportcallnumber }} - {{ getStopStatus(vessel) }} {{ getStopPhase(vessel) }}</span
					>
				</v-col>
				<v-col cols="1">
					<BerthPlannerVesselDialogNotifications
						v-if="getStopStatus(vessel) !== 'Finalizado' && functionality"
						:stop="vessel"
					></BerthPlannerVesselDialogNotifications>
				</v-col>
				<v-col cols="1">
					<v-btn icon ripple @click="closeDialog()">
						<v-icon>far fa-times</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-divider></v-divider>

			<v-row dense v-if="$store.getters.isBerthPlannerPro">
				<v-col cols="12" v-if="vessel.delayeta">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="delayEta">{{
						$t('stop.status.delayed.eta')
					}}</v-alert>
				</v-col>
				<v-col cols="12" v-if="vessel.delayetc">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="delayEtc">{{
						$t('stop.status.delayed.etc')
					}}</v-alert>
				</v-col>
				<v-col cols="12" v-if="vessel.lessthantwentyfourtoeta">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="lessThan24ToEta">{{
						$t('berthplanner.vessel.lessthantwentyfourtoeta')
					}}</v-alert>
				</v-col>
				<v-col cols="12" v-if="vessel.thirtyminstoetc">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="30ToEtc">{{
						$t('berthplanner.vessel.thirtyminstoetc')
					}}</v-alert>
				</v-col>
				<v-col cols="12" v-else-if="vessel.sixtyminstoetc">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="60ToEtc">{{
						$t('berthplanner.vessel.sixtyminstoetc')
					}}</v-alert>
				</v-col>
				<v-col cols="12" v-else-if="vessel.ninetyminstoetc">
					<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="90ToEtc">{{
						$t('berthplanner.vessel.ninetyminstoetc')
					}}</v-alert>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="4" data-test="vesseltype">
					<b> {{ $t('berthplanner.vessel.type') }}: </b>{{ vessel.vesseltype }}
				</v-col>
				<v-col cols="4" data-test="vessellength">
					<b>{{ $t('berthplanner.vessel.length') }}: </b>{{ vessel.vessellength }}
				</v-col>
				<v-col cols="4" data-test="vesselbeam">
					<b>{{ $t('berthplanner.vessel.beam') }}: </b>{{ vessel.vesselbeam }}
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="4" data-test="companyname">
					<b>{{ $t('berthplanner.vessel.terminal') }}: </b>{{ vessel.companyname }}
				</v-col>
				<v-col cols="4" data-test="bollardini">
					<b>{{ $t('berthplanner.vessel.bollards') }}: </b>
					{{ isNaN(vessel.bollardini) ? '' : vessel.bollardini }}
					-
					{{ isNaN(vessel.bollardend) ? '' : vessel.bollardend }}
				</v-col>
				<v-col cols="4" data-test="berthingposition">
					<b>{{ $t('berthplanner.vessel.berthingtype') }}: </b>{{ vessel.berthingposition }}
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="12"
					><h3>{{ $t('berthplanner.portstayinformation') }}</h3></v-col
				>
			</v-row>
			<v-row dense v-if="showEtaEtdAgent(vessel) && $store.getters.isBerthPlannerPro" :style="{ 'margin-left': '10px' }">
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.eta"
						:label="$t('berthplanner.vessel.eta.title')"
						:value="$dateTimeUtils.formatDate(vessel.eta, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4" />
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.etd"
						:label="$t('berthplanner.vessel.etd.title')"
						:value="$dateTimeUtils.formatDate(vessel.etd, this.dateTimeFormat)"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="showEtaEtdTerminal(vessel)" :style="{ 'margin-left': '10px' }">
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.etaterminal"
						:label="$t('berthplanner.vessel.etaterminal')"
						:value="$dateTimeUtils.formatDate(vessel.etaterminal, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.etc"
						:label="$t('berthplanner.vessel.etc.title')"
						:value="$dateTimeUtils.formatDate(vessel.etc, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.etdterminal"
						:label="$t('berthplanner.vessel.etdterminal')"
						:value="$dateTimeUtils.formatDate(vessel.etdterminal, this.dateTimeFormat)"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="showAtaAtd(vessel)" :style="{ 'margin-left': '10px' }">
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.ata"
						:label="$t('berthplanner.vessel.ata.title')"
						:value="$dateTimeUtils.formatDate(vessel.ata, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4"> </v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.atd"
						:label="$t('berthplanner.vessel.atd.title')"
						:value="$dateTimeUtils.formatDate(vessel.atd, this.dateTimeFormat)"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="showAtaAtdTerminal(vessel)" :style="{ 'margin-left': '10px' }">
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.ataterminal"
						:label="$t('berthplanner.vessel.ataterminal')"
						:value="$dateTimeUtils.formatDate(vessel.ataterminal, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.atc"
						:label="$t('berthplanner.vessel.atc.title')"
						:value="$dateTimeUtils.formatDate(vessel.atc, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.atdterminal"
						:label="$t('berthplanner.vessel.atdterminal')"
						:value="$dateTimeUtils.formatDate(vessel.atdterminal, this.dateTimeFormat)"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="showRtsRtsTerminal(vessel)" :style="{ 'margin-left': '10px' }">
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.rts"
						:label="$t('berthplanner.vessel.rts')"
						:value="$dateTimeUtils.formatDate(vessel.rts, this.dateTimeFormat)"
					/>
				</v-col>
				<v-col cols="4">
					<berth-planner-field
						v-if="vessel.rtsterminal"
						:label="$t('berthplanner.vessel.rtsterminal')"
						:value="$dateTimeUtils.formatDate(vessel.rtsterminal, this.dateTimeFormat)"
					/>
				</v-col>
			</v-row>

			<v-row dense v-if="stopPosition && showMap(vessel)">
				<v-col cols="12"
					><h3>{{ $t('berthplanner.realtimelocation') }}</h3></v-col
				>
			</v-row>
			<v-row dense v-if="stopPosition && showMap(vessel)">
				<v-col cols="12">
					<operations-visit-map-content :stopPosition="stopPosition" />
				</v-col>
			</v-row>
			<v-row dense v-if="showTimeline(vessel)">
				<v-col cols="12">
					<berth-planner-vessel-timeline :stop="vessel" />
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import OperationsVisitMapContent from '@/components/operations/gantt/OperationsVisitMapContent.vue';
import BerthPlannerField from './BerthPlannerField.vue';
import BerthPlannerVesselTimeline from './BerthPlannerVesselTimeline.vue';
import BerthPlannerVesselDialogNotifications from './BerthPlannerVesselDialogNotifications.vue';

/** Mixins */
import AISServiceMixin from '@/mixins/AISServiceMixin';

export default {
	mixins: [AISServiceMixin],

	components: { BerthPlannerField, BerthPlannerVesselTimeline, BerthPlannerVesselDialogNotifications, OperationsVisitMapContent },

	props: {
		vessel: {
			type: Object
		}
	},

	data() {
		return {
			stopPosition: null
		};
	},

	created() {
		this.getAisPosition(this.vessel);
	},

	computed: {
		vesselimonameportcallnumber() {
			if (!this.vessel) {
				return '';
			}
			return this.vessel.portcallnumber
				? `${this.vessel.vesselimo} - ${this.vessel.vesselname} (${this.vessel.portcallnumber})`
				: `${this.vessel.vesselimo} - ${this.vessel.vesselname}`;
		},
		dateTimeFormat() {
			return `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
		},
		functionality() {
			return this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION');
		}
	},
	methods: {
		async getAisPosition(stop) {
			this.stopPosition = await this.getAISVesselsPosition(stop);
		},
		getStopStatus(stop) {
			return stop.status ? this.$t(`stop.status.${stop.statusid.toLowerCase()}.title`) : stop.status;
		},
		getStopPhase(stop) {
			const statusValues = ['IN', 'SO', 'EO'];
			return stop.phase && statusValues.includes(stop.statusid) ? `(${stop.phase})` : '';
		},
		showEtaEtdAgent(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'IN'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showEtaEtdTerminal(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'IN', 'NR'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showAtaAtd(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showAtaAtdTerminal(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showRtsRtsTerminal(stop) {
			return stop.vesselimo && stop.statusid == 'FI';
		},
		showMap(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'NR'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showTimeline(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			console.log('showTimeline', stop.vesselimo && statusValues.includes(stop.statusid));
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		closeDialog() {
			this.$emit('close-dialog');
		}
	}
};
</script>
