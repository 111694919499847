<template>
	<div id="map-popup" class="ol-popup">
		<!-- Vessel bbdd -->
		<ul v-if="isVesselBbdd" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.vesselname }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.stops.mmsi').toUpperCase()" :value="stop.mmsi"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.stops.vesselimo')" :value="stop.vesselimo"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.stops.portcallnumber')" :value="stop.portcallnumber"></map-popup-field>
			</li>
			<li>
				<map-popup-field
					:label="$t('operations.stops.vessellength') + ' / ' + $t('operations.stops.vesselbeam')"
					:value="stop.vessellength + ' / ' + stop.vesselbeam"
					suffix="m"
				></map-popup-field>
			</li>

			<li>
				<map-popup-field :label="$t('operations.stops.vesseltype')" :value="stop.vesseltypedescription"></map-popup-field>
			</li>

			<li>
				<map-popup-field :label="$t('operations.stops.status')" :value="stop.status"></map-popup-field>
			</li>

			<li>
				<map-popup-field :label="$t('operations.stops.num')" :value="stop.num"></map-popup-field>
			</li>
			<li>
				<map-popup-field
					:label="$t('operations.stops.iniendbollard')"
					:value="(stop.inibollard || '') + ' / ' + (stop.endbollard || '')"
				></map-popup-field>
			</li>

			<li>
				<map-popup-field :label="$t('operations.stops.shipownername')" :value="stop.consigneename"></map-popup-field>
			</li>
			<li v-if="stop.statusid == 'IN'">
				<map-popup-field :label="$t('operations.ais.ata')" :value="convertDate(stop.ata)"></map-popup-field>
			</li>
			<li v-else>
				<map-popup-field :label="$t('operations.ais.eta')" :value="convertDate(stop.eta)"></map-popup-field>
			</li>
			<li v-if="stop.statusid != 'IN'">
				<map-popup-field :label="$t('operations.ais.etd')" :value="convertDate(stop.etd)"></map-popup-field>
			</li>
		</ul>
		<!-- Vessel AIS -->
		<ul v-if="isVesselAis" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.vesselname }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.ais.mmsi')" :value="stop.mmsi"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.stops.vesselimo')" :value="stop.imo"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.stops.callsign')" :value="stop.callSign"></map-popup-field>
			</li>
			<!-- Eslora y manga -->
			<li>
				<map-popup-field
					:label="$t('operations.stops.vessellength') + ' / ' + $t('operations.stops.vesselbeam')"
					:value="(stop.length || stop.vessellength) + ' / ' + (stop.beam || stop.vesselbeam)"
					suffix="m"
				></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.ais.draught')" :value="stop.draught" suffix="m"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.ais.speed')" :value="stop.speed" suffix="Kn"></map-popup-field>
			</li>
			<li>
				<map-popup-field
					:label="$t('operations.ais.speed') + ' / ' + $t('operations.ais.heading')"
					:value="stop.speed + 'kn / ' + convertToTwoDecimals(radiantsToDegrees(stop.rotation)) + 'º'"
				></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.ais.eta')" :value="convertDate(stop.eta)"></map-popup-field>
			</li>
			<li>
				<map-popup-field v-if="stop.locodeDesc" :label="$t('operations.ais.destination')" :value="stop.locodeDesc"></map-popup-field>
			</li>
			<li>
				<map-popup-field
					v-if="stop.lastUpdate"
					:label="$t('operations.ais.lastUpdate')"
					:value="convertDate(stop.lastUpdate)"
				></map-popup-field>
			</li>
		</ul>

		<!-- Event waypoint -->
		<ul v-if="isEventWaypoint" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.shipname }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.track.date')" :value="convertDate(stop.aisupdatedate)"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.track.speed')" :value="stop.speed" suffix="Kn"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.track.type')" :value="stop.eventType"></map-popup-field>
			</li>
			<li v-if="stop.geomdesc">
				<map-popup-field :label="$t('operations.track.geomdesc')" :value="stop.geomdesc"></map-popup-field>
			</li>
		</ul>

		<!-- Event line -->
		<ul v-if="isEventLine" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.shipname }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.track.date')" :value="convertDate(stop.aisupdatedate)"></map-popup-field>
			</li>
			<li>
				<map-popup-field :label="$t('operations.track.speed')" :value="convertToTwoDecimals(stop.speed)" suffix="Kn"></map-popup-field>
			</li>
		</ul>

		<!-- Movement waypoint -->
		<ul v-if="isMovementWaypoint" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.locationDes }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.movementsequence.arrivalTime')" :value="convertDate(stop.arrivalTime)"></map-popup-field>
			</li>
			<li v-if="stop.actualDate">
				<map-popup-field :label="$t('operations.movementsequence.actualDate')" :value="convertDate(stop.actualDate)"></map-popup-field>
			</li>
			<li v-if="stop.delaySeconds">
				<map-popup-field
					:label="$t('operations.movementsequence.delaySeconds')"
					:value="convertToTwoDecimals(stop.delaySeconds / 60)"
					suffix="min"
				></map-popup-field>
			</li>
		</ul>

		<!-- CEP LAYER -->
		<ul v-if="isCepLayer" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.name }}</b>
			</li>
			<li>
				<map-popup-field :label="$t('operations.cep.zone')" :value="stop.zonedescription"></map-popup-field>
			</li>
		</ul>

		<!-- Vessel AIS last position -->
		<ul v-if="isVesselAisPos" class="custom-list">
			<li class="title-popup">
				<b>{{ stop.vesselname }}</b>
			</li>
			<li>
				<map-popup-field
					v-if="stop.aisupdatedate"
					:label="$t('operations.ais.lastUpdate')"
					:value="convertDate(stop.aisupdatedate)"
				></map-popup-field>
			</li>
		</ul>
	</div>
</template>

<script>
import MapPopupField from './MapPopupField.vue';
import { CEP_ZONES_ID } from '@/components/operations/map/constants/layers';

export default {
	components: { MapPopupField },
	props: {
		stop: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},
	computed: {
		isVesselBbdd() {
			return (
				this.stop &&
				!this.stop.isAis &&
				!this.stop.isAisLastPos &&
				!this.stop.isEventWaypoint &&
				!this.stop.isEventLine &&
				!this.stop.isMovementWaypoint &&
				this.stop.idLayer != CEP_ZONES_ID
			);
		},
		isVesselAis() {
			return (
				this.stop &&
				this.stop.isAis &&
				!this.stop.isAisLastPos &&
				!this.stop.isEventWaypoint &&
				!this.stop.isEventLine &&
				!this.stop.isMovementWaypoint &&
				this.stop.idLayer != CEP_ZONES_ID
			);
		},
		isEventWaypoint() {
			return this.stop && this.stop.isEventWaypoint && !this.stop.isEventLine;
		},
		isEventLine() {
			return this.stop && this.stop.isEventLine;
		},
		isMovementWaypoint() {
			return this.stop && this.stop.isMovementWaypoint;
		},
		isCepLayer() {
			return this.stop && this.stop.idLayer == CEP_ZONES_ID;
		},
		isVesselAisPos() {
			return this.stop && this.stop.isAisLastPos;
		}
	},
	methods: {
		convertDate(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		},
		convertToTwoDecimals(value) {
			return Math.floor(value * 100) / 100;
		},
		radiantsToDegrees(radiants) {
			var grados = radiants * (180 / Math.PI);
			return grados;
		}
	}
};
</script>
<style scoped>
@import '../../../../../../styles/app-variables.pcss';
.custom-list {
	list-style-type: none;
	padding: 2px;
	margin: 0;
}
.custom-list > .title-popup {
	padding-bottom: 10px;
	background-color: var(--primary);
	text-align: center;
	height: 20px;
}

.custom-list > li > p {
	margin-bottom: 0;
}

.custom-list > li > b {
	font-size: 14px;
	color: #ffffff;
}

.ol-popup {
	width: auto;
	border-radius: 0;
}
</style>
