import DateService from '@/services/date.service.js';

export default {
	data() {
		return {
			dateTimeFormat: 'dd/MM/yyyy HH:mm',
			timeFormat: 'HH:mm',
		}
	},
	methods: {
		dateFormat(date, format) {
			return (date && format) ? DateService.format(date, format) : '';
		}
	}
}
