// Calculated distance between bollards in excel file
const distanceInMetersPerBollardInMuelleEste = {
	1: 0,
	2: 34,
	3: 66,
	4: 98,
	5: 135,
	6: 167,
	7: 199,
	8: 237,
	9: 269,
	10: 301,
	11: 338,
	12: 370,
	13: 402,
	14: 440,
	15: 472,
	16: 504,
	17: 542,
	18: 574,
	19: 606,
	20: 644,
	21: 676,
	22: 708,
	23: 745,
	24: 777,
	25: 810,
	26: 847
}

const distanceInMetersPerBollardInMuelleJuanCarlosI = {
	0: 0,
	1:22,
	2:53,
	3:77,
	4:105,
	5:135,
	6:159,
	7:187,
	8:217,
	9:240,
	10:267,
	11:289,
	12:311,
	13:322,
	14:355,
	15:387,
	16:420,
	17:451,
	18:482,
	19:516,
	20:547,
	21:579,
	22:611,
	23:643,
	24:675,
	25:702,
	26:730,
	27:759,
	28:784,
	29:809,
	30:834,
	31:858,
	32:884,
	33:912,
	34:941,
	35:971,
	36:1000,
	37:1029,
	38:1058,
	39:1088,
	40:1116,
	41:1149,
	42:1174,
	43:1201,
	44:1230,
	45:1260,
	46:1289,
	47:1318,
	48:1349,
	49:1379,
	50:1408,
	51:1435,
	52:1462,
	53:1491,
	54:1519,
	55:1551,
	56:1575,
	57:1603,
	58:1631,
	59:1658,
	60:1687,
	61:1717,
	62:1741,
	63:1769,
	64:1790,
	65:1822,
	66:1854,
	67:1886,
	68:1918,
	69:1950,
	70:1982,
	71:2003,
	72:2025
}

// Calculated distance between bollards in excel file
const distanceInMetersPerBollardInMuelleOPCSA = {
	1:0,
	2:9,
	3:31,
	4:52,
	5:73,
	6:95,
	7:116,
	8:138,
	9:159,
	10:181,
	11:202,
	12:223,
	13:245,
	14:266,
	15:287,
	16:309,
	17:330,
	18:352,
	19:373,
	20:395,
	21:416,
	22:437,
	23:459,
	24:480,
	25:501,
	26:523,
	27:544,
	28:566,
	29:587,
	30:608,
	31:630,
	32:651,
	33:674,
	34:696,
	35:718,
	36:740,
	37:763,
	38:785,
	39:807,
	40:829,
	41:852,
	42:874,
	43:896,
	44:919,
	45:941,
	46:953,
	47:972,
	48:996,
	49:1021,
	50:1048,
	51:1070,
	52:1092,
	53:1119,
	54:1140,
	55:1168,
	56:1189,
	57:1217,
	58:1238,
	59:1265,
	60:1287,
	61:1315,
	62:1342,
	63:1364,
	64:1390,
	65:1413,
	66:1434,
	67:1459,
	68:1489,
	69:1512,
	70:1538,
	71:1559,
	72:1586,
	73:1608,
	74:1635,
	75:1657,
	76:1684,
	77:1706,
	78:1733,
	79:1754,
	80:1782,
	81:1811,
	82:1831,
	83:1854,
	84:1875
}

export default {
	getBollardDistance: function(quay, number) {
		if (quay.berthcode === 'MUESTE') {
			return distanceInMetersPerBollardInMuelleEste[number];
		} else if (quay.berthcode === 'JCI') {
			return distanceInMetersPerBollardInMuelleJuanCarlosI[number];
		} else if (quay.berthcode === 'OPCSA') {
			return distanceInMetersPerBollardInMuelleOPCSA[number];
	}
}
}
