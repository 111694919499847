<template>
	<div id="bp_container">
		<table class="bp_table">
			<tbody>
				<tr>
					<td class="bp_table_first"></td>
					<td class="bp_table_second">
						<div id="bollards_axis_container" class="bp_table_axis">
							<div class="bp_table_axis_bollard" v-for="bollard in bollards" :key="bollard.index" :style="bollard.styleLabel">
								{{ bollard.label }}
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div id="days_axis_container" class="bp_table_axis">
							<div class="bp_table_axis_day" v-for="(day, index) in days" :key="index" :style="day.style">
								<div class="bp_table_axis_day_labels" v-if="day.visible">
									<div class="bp_table_axis_day_labels_bold">{{ day.weekDayLabel }}</div>
									<div class="bp_table_axis_day_labels_bold">{{ day.dayLabel }}</div>
									<div class="bp_table_axis_day_labels_tempwind">{{ day.tempLabel }}</div>
									<div
										class="bp_table_axis_day_labels_tempwind"
										:class="{
											bp_table_axis_day_labels_tempwind_windAlert: checkWindSafety(
												day.windLabel === undefined ? '' : day.windLabel
											)
										}"
									>
										<v-tooltip v-if="checkWindSafety(day.windLabel === undefined ? '' : day.windLabel)" right>
											<template v-slot:activator="{ on, attrs }">
												<span v-on="on" v-bind="attrs"> {{ day.windLabel }} </span>
											</template>
											<span>{{ windAlertMessage }}</span>
										</v-tooltip>
										<span v-else-if="!checkWindSafety(day.windLabel === undefined ? '' : day.windLabel)">{{
											day.windLabel
										}}</span>
									</div>
								</div>
								<div class="bp_table_axis_day_hour" v-for="(hour, index2) in day.hours" :key="index2" :style="hour.style">
									{{ hour.label }}
								</div>
							</div>
						</div>
					</td>
					<td>
						<div class="bp_table_main">
							<div class="bp_table_main_bollard" v-for="bollard in bollards" :key="bollard.index" :style="bollard.style"></div>
							<div class="bp_table_main_line" v-for="line in dayLines" :key="line.index" :style="line.style"></div>
							<div class="bp_table_main_now" :style="now.style" />
							<div v-for="vessel in vessels" :key="vessel.index" @click.prevent="showVesselDialog(vessel.stop)">
								<berth-planner-vessel :vessel="vessel" />
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<!-- Vessel Info Dialog -->
		<div class="text-center">
			<v-dialog v-model="vesselDialog" max-width="900" eager>
				<berth-planner-vessel-dialog v-if="vesselDialog" :vessel="vessel" v-on:close-dialog="vesselDialog = false" />
			</v-dialog>
		</div>
	</div>
</template>

<script>
import BerthPlannerVessel from './BerthPlannerVessel.vue';
import BerthPlannerVesselDialog from './BerthPlannerVesselDialog.vue';

import BerthPlannerMixin from '@/mixins/BerthPlannerMixin';

import WeatherService from '@/services/weather.service.js';
import BollardsService from '@/services/bollards.service.js';

export default {
	components: { BerthPlannerVessel, BerthPlannerVesselDialog },
	mixins: [BerthPlannerMixin],
	props: {
		firstWeekDay: {
			type: Date,
			required: true
		},
		lastWeekDay: {
			type: Date,
			required: true
		},
		dailyForecast: {
			type: Array
		},
		quay: {
			type: Object,
			required: true
		},
		stops: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			maxWidth: window.innerWidth - 64, // 64 = menu width
			maxHeight: window.innerHeight - 64 - 60, // 64 = toolbar height, 60 = footer height
			widthSecurityMargin: 200,
			heightSecurityMargin: 30,
			weekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			bollards: [],
			days: [],
			dayLines: [],
			vessels: [],
			vesselDialog: false,
			vessel: {},
			now: {
				date: new Date(),
				style: {}
			},
			windAlertMessage: 'Wind speed forecast exceeds wind speed allowed to carry out operations',
			safeWindSpeedLimit: 100
		};
	},
	mounted() {
		const bp_container = document.getElementById('bp_container');
		bp_container.style.width = this.maxWidth + 'px';
		bp_container.style.height = this.maxHeight + 'px';
		//bp_container.style.height = (this.maxHeight + 30) + 'px';

		const difference = this.lastWeekDay.getTime() - this.firstWeekDay.getTime();
		this.numberOfDays = Math.round(difference / (1000 * 60 * 60 * 24));
		this.distancePerDay = (this.maxHeight - this.heightSecurityMargin) / this.numberOfDays;
		//this.distancePerDay = (this.maxHeight - this.heightSecurityMargin) / (this.numberOfDays - 1);
		this.distancePerHour = this.distancePerDay / 4;

		const lineHeight = this.maxHeight - this.heightSecurityMargin;
		//const lineHeight = this.maxHeight - this.heightSecurityMargin + 30;

		if (this.quay.berthcode === 'MUESTE') {
			this.createBollardsAxisMuelleEste(lineHeight);
		} else if (this.quay.berthcode === 'JCI') {
			this.createBollardsAxisMuelleJuanCarlosI(lineHeight);
		} else if (this.quay.berthcode === 'TEC I') {
			this.createBollardsAxisMuelleTecI(lineHeight);
		} else if (this.quay.berthcode === 'OPCSA') {
			this.createBollardsAxisMuelleOPCSA(lineHeight);
		} else if (this.quay.berthcode === 'BOLP') {
			this.createBollardsAxisMuelleBoludaLP(lineHeight);
		} else {
			this.createBollardsAxis(lineHeight);
		}
		this.createDaysAxis();
		this.createVessels();
		this.checkWindSpeedCompany();
	},
	methods: {
		createBollardsAxisMuelleEste(lineHeight) {
			const bollardsDistance = 847; // FIXME: this is the MUELLE ESTE amount of meters
			const bollardsMaxWidth = this.maxWidth - this.widthSecurityMargin;

			for (let i = this.quay.initbolcode; i <= this.quay.endbolcode; i++) {
				let distance = (BollardsService.getBollardDistance(this.quay, i) * bollardsMaxWidth) / bollardsDistance;
				this.bollards.push({
					index: 'b_' + i,
					id: i,
					label: i,
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: `left: ${distance}px; height: ${lineHeight}px;`
				});
			}
		},
		createBollardsAxisMuelleJuanCarlosI(lineHeight) {
			const bollardsDistance = 2025; // FIXME: this is the MUELLE JUAN CARLOS I amount of meters
			const numberOfBollards = 73;
			const bollardsMaxWidth = this.maxWidth - this.widthSecurityMargin;

			let j = 41;
			for (let i = 0; i < numberOfBollards; i++) {
				let distance = (BollardsService.getBollardDistance(this.quay, i) * bollardsMaxWidth) / bollardsDistance;
				this.bollards.push({
					index: 'b_' + i,
					id: i < 12 ? 82 - i : i < 65 ? i + j : 178 - i,
					label: i % 2 === 0 ? (i < 12 ? 81 - i : i < 65 ? i + j : 179 - i) : '',
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: i >= 65 ? `left: ${distance - 21}px; height: ${lineHeight}px;` : `left: ${distance}px; height: ${lineHeight}px;`
				});

				if (i >= 12 && i < 65) {
					j = j - 2;
				}
			}
		},
		createBollardsAxisMuelleTecI(lineHeight) {
			const numberOfBollards = this.quay.endbolcode - this.quay.initbolcode;
			const distancePerBollard = (this.maxWidth - this.widthSecurityMargin) / numberOfBollards;

			for (let i = this.quay.endbolcode, j = 0; i >= this.quay.initbolcode; i--, j++) {
				let distance = 0;
				if (j > 0) {
					distance = distancePerBollard + this.bollards[j - 1].distance;
				}
				this.bollards.push({
					index: 'b_' + i,
					id: i,
					label: i % 2 !== 0 ? i : '',
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: `left: ${distance}px; height: ${lineHeight}px;`
				});
			}
		},
		createBollardsAxisMuelleOPCSA(lineHeight) {
			const bollardsDistance = 1875; // FIXME: this is the OPCSA berth amount of meters
			const numberOfBollards = 84;
			const bollardsMaxWidth = this.maxWidth - this.widthSecurityMargin;

			for (let i = 1; i <= numberOfBollards; i++) {
				let distance = (BollardsService.getBollardDistance(this.quay, i) * bollardsMaxWidth) / bollardsDistance;
				this.bollards.push({
					index: 'b_' + i,
					id: i,
					label: i % 2 !== 0 ? i : '',
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: `left: ${distance}px; height: ${lineHeight}px;`
				});
			}
		},
		createBollardsAxisMuelleBoludaLP(lineHeight) {
			const numberOfBollards = 46;
			const distancePerBollard = (this.maxWidth - this.widthSecurityMargin) / numberOfBollards;

			let d = 8;
			for (let i = 0, j = 0; i < numberOfBollards; i++, j++) {
				let distance = 0;
				if (j > 0) {
					distance = distancePerBollard + this.bollards[j - 1].distance;
				}
				this.bollards.push({
					index: 'b_' + i,
					id: i < 10 ? 11 - i : i - d,
					label: i % 2 !== 0 ? (i < 10 ? 11 - i : i - d) : '',
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: `left: ${distance}px; height: ${lineHeight}px;`
				});
			}
		},
		createBollardsAxis(lineHeight) {
			if (!this.quay.initbolcode && !this.quay.endbolcode) {
				this.quay.initbolcode = 1;
				this.quay.endbolcode = 2;
			}
			const numberOfBollards = this.quay.endbolcode - this.quay.initbolcode;
			const distancePerBollard = (this.maxWidth - this.widthSecurityMargin) / numberOfBollards;

			const initBollard = parseInt(this.quay.initbolcode);
			const endBollard = parseInt(this.quay.endbolcode);

			for (let i = initBollard, j = 0; i <= endBollard; i++, j++) {
				let distance = 0;
				if (j > 0) {
					distance = distancePerBollard + this.bollards[j - 1].distance;
				}
				this.bollards.push({
					index: 'b_' + i,
					id: i,
					label: numberOfBollards <= 1 ? '' : i,
					distance: distance,
					style: `left: ${distance}px; height: ${lineHeight}px;`,
					styleLabel: `left: ${distance}px; height: ${lineHeight}px;`
				});
			}
		},
		createDaysAxis() {
			const lineWidth = this.maxWidth - this.widthSecurityMargin;
			let dayLineIndex = 1;

			for (let i = 0; i < this.numberOfDays; i++) {
				let distance = 0;
				let newDay = new Date(this.firstWeekDay);
				if (i > 0) {
					distance = this.distancePerDay + this.days[i - 1].distance;
					newDay.setDate(newDay.getDate() + i);
				}

				let tempLabel, windLabel;
				const forecastInfo = this.getForecastInfo(newDay);
				if (forecastInfo) {
					tempLabel = Math.round(forecastInfo.temp.day * 10) / 10 + 'ºC';
					windLabel = forecastInfo.windSpeed + ' m/s (' + forecastInfo.windDirection + ')';
				}

				this.days.push({
					date: newDay,
					weekDayLabel: this.weekDay[newDay.getDay()],
					dayLabel: `${newDay.getDate()}/${newDay.getMonth() + 1}`,
					tempLabel,
					windLabel,
					distance,
					style: `top: ${distance}px;`,
					hours: [
						{ label: '02:00', style: `top: 0px;` },
						{ label: '08:00', style: `top: ${this.distancePerHour}px;` },
						{ label: '14:00', style: `top: ${this.distancePerHour * 2}px;` },
						{ label: '20:00', style: `top: ${this.distancePerHour * 3}px;` }
					],
					visible: true
				});

				let numberOfHours = 4;
				//if (i + 1 === this.numberOfDays) {
				//		numberOfHours = 1;
				//		this.days[this.days.length - 1].hours.splice(1);
				//		this.days[this.days.length - 1].visible = false;
				//	}

				for (let j = 0; j < numberOfHours; j++) {
					let distance = 0;
					let style = '';

					if (i === 0 && j === 0) {
						distance = 20;
						style = `top: ${distance}px; width: ${lineWidth}px; border: solid 1px #336799;`;
					} else {
						const lastDistance = this.dayLines[this.dayLines.length - 1].distance;
						distance = lastDistance + this.distancePerHour;
						if (j === 0) {
							style = `top: ${distance}px; width: ${lineWidth}px; border: solid 1px #336799;`;
						} else {
							style = `top: ${distance}px; width: ${lineWidth}px;`;
						}
					}

					const date = new Date(newDay);
					switch (j) {
						case 0:
							date.setHours(2, 0, 0);
							break;
						case 1:
							date.setHours(8, 0, 0);
							break;
						case 2:
							date.setHours(14, 0, 0);
							break;
						case 3:
							date.setHours(20, 0, 0);
							break;
					}

					if (this.now.date.getTime() === date.getTime()) {
						this.now.style = `top: ${distance}px; width: ${lineWidth}px;`;
					} else if (this.dayLines.length > 0) {
						const previousDate = this.dayLines[this.dayLines.length - 1].date;
						const previousDistance = this.dayLines[this.dayLines.length - 1].distance;
						if (this.now.date.getTime() > previousDate.getTime() && this.now.date.getTime() < date.getTime()) {
							const nowDistance = previousDistance + this.distancePerHour / 2;
							this.now.style = `top: ${nowDistance}px; width: ${lineWidth}px;`;
						}
					}

					this.dayLines.push({ index: 'd_' + dayLineIndex++, date, distance, style });
				}
			}
		},
		createVessels() {
			let vesselIndex = 1;
			const MULTI_BOLLARDS_BERTHS = ['105']; //berth with duplicated bollard numbers

			const REVERSE_BOLLARDS_BERTHS = [29]; //berths with bollards in reverse order

			const BABOR_BERTH_POSITION = ['DOCKED-PORT', 'ALONGSIDE-PORT', 'PORTSIDE'];

			const stopsWrongBollards = [];

			for (let i = 0; i < this.stops.length; i++) {
				const stop = this.stops[i];
				const name = stop.vesselname;

				let bollardini = REVERSE_BOLLARDS_BERTHS.some((element) => element === stop.berthid)
					? parseInt(stop.bollardini) < parseInt(stop.bollardend)
						? stop.bollardend
						: stop.bollardini
					: parseInt(stop.bollardini) < parseInt(stop.bollardend)
					? stop.bollardini
					: stop.bollardend;

				let bollardend = REVERSE_BOLLARDS_BERTHS.some((element) => element === stop.berthid)
					? parseInt(stop.bollardend) > parseInt(stop.bollardini)
						? stop.bollardini
						: stop.bollardend
					: parseInt(stop.bollardend) > parseInt(stop.bollardini)
					? stop.bollardend
					: stop.bollardini;

				if (!bollardini) {
					bollardini = this.quay.initbolcode;
				}
				if (!bollardend) {
					bollardend = this.quay.endbolcode;
				}

				let bollardiniPosition = this.bollards.find((bol) => bol.id === parseInt(bollardini));
				let bollardendPosition = this.bollards.find((bol) => bol.id === parseInt(bollardend));

				if (MULTI_BOLLARDS_BERTHS.some((element) => element === stop.berthcode)) {
					bollardiniPosition = this.bollards
						.filter((b) => b.id === parseInt(bollardini))
						.reverse()
						.find((bol) => bol.id === parseInt(bollardini));
					bollardendPosition = this.bollards
						.filter((b) => b.id === parseInt(bollardend))
						.reverse()
						.find((bol) => bol.id === parseInt(bollardend));
				}

				const bollardiniNextPosition = bollardini % 1 != 0 ? this.bollards.find((bol) => bol.id === parseInt(bollardini + 1)) : undefined;
				const bollardendNextPosition = bollardend % 1 != 0 ? this.bollards.find((bol) => bol.id === parseInt(bollardend + 1)) : undefined;

				if (!bollardiniPosition || !bollardendPosition || bollardiniPosition.distance > bollardendPosition.distance) {
					console.log('Not bollards for vessel:', name);
					stopsWrongBollards.push(stop);
					continue;
				}

				let styleTopCalc, styleHeightCalc;
				for (let j = 0; j < this.dayLines.length; j++) {
					//for (let j = 0; j < this.dayLines.length - 1; j++) {
					const dayLine = this.dayLines[j];

					if (!styleTopCalc) {
						if (stop.etaplannerlocal.getTime() < dayLine.date.getTime()) {
							styleTopCalc = j === 0 ? 1 : this.dayLines[j - 1].distance;

							if (styleTopCalc > 1) {
								let previousDayLineDistance = this.dayLines[j - 1].distance;
								let previousDayLineDate = this.dayLines[j - 1].date;
								while (previousDayLineDate.getTime() < stop.etaplannerlocal.getTime()) {
									previousDayLineDistance += this.distancePerHour / 6;
									previousDayLineDate = new Date(previousDayLineDate.getTime() + 60 * 60 * 1000); // add 1 hour
								}
								styleTopCalc = previousDayLineDistance;
							}
						} else if (stop.etaplannerlocal.getTime() === dayLine.date.getTime()) {
							styleTopCalc = dayLine.distance;
						}
					}
					if (!styleHeightCalc) {
						if (stop.etdplannerlocal.getTime() < dayLine.date.getTime()) {
							styleHeightCalc = j === 0 ? 1 : this.dayLines[j - 1].distance;

							if (styleHeightCalc > 1) {
								let previousDayLineDistance = this.dayLines[j - 1].distance;
								let previousDayLineDate = this.dayLines[j - 1].date;
								while (previousDayLineDate.getTime() < stop.etdplannerlocal.getTime()) {
									previousDayLineDistance += this.distancePerHour / 6;
									previousDayLineDate = new Date(previousDayLineDate.getTime() + 60 * 60 * 1000); // add 1 hour
								}
								styleHeightCalc = previousDayLineDistance;
							}
						} else if (stop.etdplannerlocal.getTime() === dayLine.date.getTime()) {
							styleHeightCalc = dayLine.distance;
						}
					}
					if (styleTopCalc && styleHeightCalc) {
						if (styleTopCalc !== styleHeightCalc) {
							styleHeightCalc = styleHeightCalc - styleTopCalc;
						}
						break;
					}
				}

				if (
					(styleTopCalc === undefined && styleHeightCalc === undefined) ||
					(!styleTopCalc && !styleHeightCalc) ||
					styleTopCalc === styleHeightCalc ||
					styleTopCalc < 0 ||
					styleHeightCalc < 0
				) {
					console.log('Not valid ETA/ETD, out of range for this week:', name, stop.etaplanner, stop.etdplanner);
					continue;
				}

				const styleBgColor = stop.color;
				let styleBTLR = 0,
					styleBBLR = 0,
					styleBTRR = 0,
					styleBBRR = 0;

				let vesselPosition = stop.berthingposition ? stop.berthingposition.toUpperCase() : null;

				if (stop.berthseaside && BABOR_BERTH_POSITION.some((element) => element === vesselPosition)) {
					styleBTLR = stop.berthseaside === 'L' ? 25 : 0;
					styleBBLR = stop.berthseaside === 'L' ? 25 : 0;
					styleBTRR = stop.berthseaside === 'R' ? 25 : 0;
					styleBBRR = stop.berthseaside === 'R' ? 25 : 0;
				} else if (stop.berthseaside && !BABOR_BERTH_POSITION.some((element) => element === vesselPosition)) {
					styleBTLR = stop.berthseaside === 'R' ? 25 : 0;
					styleBBLR = stop.berthseaside === 'R' ? 25 : 0;
					styleBTRR = stop.berthseaside === 'L' ? 25 : 0;
					styleBBRR = stop.berthseaside === 'L' ? 25 : 0;
				} else if (this.quay.seaside && BABOR_BERTH_POSITION.some((element) => element === vesselPosition)) {
					styleBTLR = this.quay.seaside === 'L' ? 25 : 0;
					styleBBLR = this.quay.seaside === 'L' ? 25 : 0;
					styleBTRR = this.quay.seaside === 'R' ? 25 : 0;
					styleBBRR = this.quay.seaside === 'R' ? 25 : 0;
				} else {
					styleBTLR = this.quay.seaside === 'R' ? 25 : 0;
					styleBBLR = this.quay.seaside === 'R' ? 25 : 0;
					styleBTRR = this.quay.seaside === 'L' ? 25 : 0;
					styleBBRR = this.quay.seaside === 'L' ? 25 : 0;
				}

				const getDistanceFromBollards = function (bollard, bollardPosition, bollardNextPosition) {
					if (!bollardNextPosition) {
						return bollardPosition.distance;
					} else {
						const slotBetweenBollards = (bollardNextPosition.distance - bollardPosition.distance) / 10;
						const fraction = bollard - Math.floor(bollard);
						if (fraction > 0 && fraction <= 0.1) {
							return bollardPosition.distance + slotBetweenBollards;
						} else if (fraction > 0.1 && fraction <= 0.2) {
							return bollardPosition.distance + slotBetweenBollards * 2;
						} else if (fraction > 0.2 && fraction <= 0.3) {
							return bollardPosition.distance + slotBetweenBollards * 3;
						} else if (fraction > 0.3 && fraction <= 0.4) {
							return bollardPosition.distance + slotBetweenBollards * 4;
						} else if (fraction > 0.4 && fraction <= 0.5) {
							return bollardPosition.distance + slotBetweenBollards * 5;
						} else if (fraction > 0.5 && fraction <= 0.6) {
							return bollardPosition.distance + slotBetweenBollards * 6;
						} else if (fraction > 0.6 && fraction <= 0.7) {
							return bollardPosition.distance + slotBetweenBollards * 7;
						} else if (fraction > 0.7 && fraction <= 0.8) {
							return bollardPosition.distance + slotBetweenBollards * 8;
						} else if (fraction > 0.8 && fraction <= 0.9) {
							return bollardPosition.distance + slotBetweenBollards * 9;
						} else {
							return bollardPosition.distance + slotBetweenBollards * 10;
						}
					}
				};

				const styleLeft = getDistanceFromBollards(bollardini, bollardiniPosition, bollardiniNextPosition);
				const styleWidth = getDistanceFromBollards(bollardend, bollardendPosition, bollardendNextPosition) - styleLeft;
				const styleTop = styleTopCalc;
				const styleHeight = styleHeightCalc || this.maxHeight - this.heightSecurityMargin - styleTopCalc;
				const zIndexValue = stop.status === 'BERTH_BLOCK' ? 0 : 1;
				const style = {
					'background-color': styleBgColor,
					'border-top-left-radius': `${styleBTLR}px`,
					'border-bottom-left-radius': `${styleBBLR}px`,
					'border-top-right-radius': `${styleBTRR}px`,
					'border-bottom-right-radius': `${styleBBRR}px`,
					left: `${styleLeft}px`,
					width: `${styleWidth}px`,
					top: `${styleTop}px`,
					height: `${styleHeight}px`,
					'z-index': zIndexValue
				};
				if (stop.opacity) {
					style.opacity = stop.opacity;
				}
				const showAlertsTop = styleHeight > 70;
				const showVesselName =
					(styleWidth > 110 && styleHeight > 35) || (styleWidth > 200 && styleHeight > 10) || (styleWidth > 90 && styleHeight > 130);
				const showVesselDates = (styleWidth > 140 && styleHeight > 50) || (styleWidth > 300 && styleHeight > 30);
				const showVesselPhase =
					((styleWidth > 160 && styleHeight > 70) || (styleWidth > 400 && styleHeight > 50)) && ['IN', 'SO', 'EO'].includes(stop.statusid);
				const showVesselImo = !showVesselName && !showVesselDates && !showVesselPhase;

				this.vessels.push({
					index: 'v_' + vesselIndex++,
					stop,
					name,
					bollardini,
					bollardend,
					style,
					showAlertsTop,
					showVesselName,
					showVesselDates,
					showVesselPhase,
					showVesselImo
				});
			}

			this.$puiEvents.$emit('berthplanner-container_wrongBollards', stopsWrongBollards);
		},
		getForecastInfo(date) {
			if (!this.dailyForecast || !Array.isArray(this.dailyForecast)) {
				console.error('Not daily forecast!');
				return null;
			}

			for (let i = 0, dailyForecastLength = this.dailyForecast.length; i < dailyForecastLength; i++) {
				const dayForecast = this.dailyForecast[i];
				const dateForecast = new Date(dayForecast.dt * 1000);
				if (
					date.getDate() === dateForecast.getDate() &&
					date.getMonth() === dateForecast.getMonth() &&
					date.getFullYear() === dateForecast.getFullYear()
				) {
					dayForecast.windSpeed = Math.round(dayForecast.wind_speed * 10) / 10;
					dayForecast.windDirection = WeatherService.getWindDirectionFromWindDegrees(dayForecast.wind_deg);

					return dayForecast;
				}
			}

			return null;
		},
		showVesselDialog(vessel) {
			this.vessel = vessel;
			this.vesselDialog = vessel.vesselimo ? true : false;
		},
		checkWindSpeedCompany() {
			if (this.stops[0] === undefined || !this.stops[0].companyid) {
				console.log('Unable to check safeWindSpeedLimit');
			} else {
				const opts = {
					model: 'configportcompany',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'companyid',
								op: 'eq',
								data: this.stops[0].companyid
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data[0].windspeedalert) {
						this.safeWindSpeedLimit = response.data.data[0].windspeedalert;
					}
				});
			}
		},
		checkWindSafety(windSpeed) {
			if (windSpeed != '') {
				let speed = windSpeed.split('m/s')[0];

				return speed > this.safeWindSpeedLimit ? true : false;
			}

			return false;
		}
	}
};
</script>

<style lang="postcss">
.bp_table {
	width: 100%;
	height: 100%;
	&_first {
		width: 170px;
		height: 20px;
	}
	&_second {
		width: auto;
	}
	&_axis {
		position: relative;
		width: 100%;
		height: 100%;
		&_bollard {
			position: absolute;
		}
		&_day {
			position: absolute;
			left: 0px;
			width: 100%;
			&_labels {
				position: absolute;
				top: 50px;
				left: 0px;
				width: 100px;
				text-align: right;
				&_bold {
					font-weight: bold;
				}
				&_tempwind {
					font-size: 11px;
					&_windAlert {
						color: red;
						font-weight: bold;
					}
				}
			}
			&_hour {
				position: absolute;
				left: 120px;
				width: 40px;
				font-size: 12px;
				line-height: 40px;
				text-align: right;
			}
		}
	}
	&_main {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: rgb(240, 240, 240);
		&_bollard {
			position: absolute;
			top: 10px;
			width: 1px;
			height: 600px;
			background-color: rgb(204, 204, 204);
		}
		&_line {
			position: absolute;
			height: 1px;
			background-color: rgb(204, 204, 204);
		}
		&_now {
			position: absolute;
			left: -10px;
			height: 2px;
			background-color: rgb(204, 0, 0);
		}
	}
}
.bp_vessel {
	position: absolute;
	border: solid 1px rgb(255, 255, 255);

	&_container {
		display: grid;
		grid-template-columns: 0.4fr 2.2fr 0.4fr;
		grid-template-rows: 0.6fr 1.4fr;
		gap: 0px 0px;
		grid-template-areas:
			'top-left top-middle top-right'
			'bottom-left bottom-middle bottom-right';
		padding-left: 4px;
		padding-right: 4px;
	}
	&_top-left {
		grid-area: top-left;
		text-align: left;
	}
	&_top-middle {
		grid-area: top-middle;
		text-align: center;
		white-space: nowrap;
	}
	&_top-right {
		grid-area: top-right;
		text-align: right;
	}
	&_bottom-left {
		grid-area: bottom-left;
		text-align: left;
	}
	&_bottom-middle {
		grid-area: bottom-middle;
		text-align: center;
	}
	&_bottom-right {
		grid-area: bottom-right;
		text-align: right;
	}
}
</style>
