<template>
	<v-tooltip v-if="tooltip" bottom>
		<template v-slot:activator="{on, attrs}">
			<span v-on="on" v-bind="attrs"><b>{{ label }}: </b>{{ value }}</span>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
	<span v-else><b>{{ label }}: </b>{{ value }}</span>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		value: {
			type: [String, Number],
			default: ''
		},
		tooltip: {
			type: String,
			required: false
		}
	}
};
</script>
