<template>
	<div>
		<!-- Gantt Diagram -->
		<div v-if="cartoReady" class="gantt" ref="gantt"></div>

		<!-- Vessel Info Dialog -->
		<div class="text-center">
			<v-dialog v-model="vesselDialog" eager>
				<berth-planner-vessel-dialog v-if="vesselDialog" :vessel="vessel" v-on:close-dialog="vesselDialog = false" />
			</v-dialog>
		</div>
	</div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//am4core.useTheme(am4themes_animated);

import CartoMixin from '@/mixins/CartoMixin.js';
import BerthPlannerVesselDialog from './BerthPlannerVesselDialog.vue';

export default {
	components: { BerthPlannerVesselDialog },
	mixins: [CartoMixin],
	props: {
		firstWeekDay: {
			type: Date,
			required: true
		},
		lastWeekDay: {
			type: Date,
			required: true
		},
		dailyForecast: {
			type: Array,
			required: true
		},
		quay: {
			type: Object,
			required: true
		},
		stops: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			maxHeight: window.innerHeight - 64 - 30 + 'px', // 64 = toolbar height, 30 = footer height
			ganttChart: {
				chart: null,
				berthValueAxis: null,
				berthSeries: null
			},
			now: new Date(),
			vesselDialog: false,
			vessel: {}
		};
	},
	mounted() {
		// am4chart gantt
		this.colorSet = new am4core.ColorSet();
		this.colorSet.saturation = 0.4;

		// Draw chart
		this.drawGanttChart(this.stops);
	},
	methods: {
		drawGanttChart(data) {
			const self = this;
			// Chart
			this.ganttChart.chart = am4core.create(this.$refs['gantt'], am4charts.XYChart);
			this.ganttChart.chart.topAxesContainer.layout = 'horizontal';
			this.ganttChart.chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:SSZ';
			this.ganttChart.chart.dateFormatter.utc = false;
			this.ganttChart.chart.svgContainer.htmlElement.style.height = this.maxHeight;

			// Date Axis
			this.ganttChart.dateAxis = this.ganttChart.chart.yAxes.push(new am4charts.DateAxis());
			this.ganttChart.dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm';
			this.ganttChart.dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
			this.ganttChart.dateAxis.strictMinMax = true;
			this.ganttChart.dateAxis.min = this.firstWeekDay.getTime(); // first day of the week
			this.ganttChart.dateAxis.max = this.lastWeekDay.getTime(); // last day of the week
			this.ganttChart.dateAxis.resizable = true;
			this.ganttChart.dateAxis.renderer.tooltipLocation = 0;
			this.ganttChart.dateAxis.renderer.inversed = true;
			this.ganttChart.dateAxis.renderer.labels.template.textAlign = 'end';
			this.ganttChart.dateAxis.renderer.grid.template.strokeOpacity = 0;
			this.ganttChart.dateAxis.renderer.grid.template.strokeWidth = 0;
			this.ganttChart.dateAxis.paddingRight = 65;
			this.ganttChart.dateAxis.dateFormats.setKey('day', 'eeee \n dd/MM');
			this.ganttChart.dateAxis.periodChangeDateFormats.setKey('day', 'eeee \n dd/MM');
			//this.ganttChart.dateAxis.fontWeight = "bolder";
			this.ganttChart.dateAxis.gridIntervals.setAll([{ timeUnit: 'day', count: 1 }]);

			this.ganttChart.dateAxis.renderer.labels.template.adapter.add('text', (text, target) => {
				if (!text) {
					return text;
				}
				if (text.includes(':')) {
					// text is the hour label
					return text;
				}

				const forecastInfo = self.getForecastInfo(target.dataItem.dates.date);
				if (!forecastInfo) {
					return `[bold]${text}[/]`;
				}

				const temp = Math.round(forecastInfo.temp.day * 10) / 10;
				const windSpeed = Math.round(forecastInfo.wind_speed * 10) / 10;
				const windDirection = self.getWindDirectionFromWindDegrees(forecastInfo.wind_deg);
				return `[bold]${text}[/] \n \n [font-size: 12px]${temp} ºC \n ${windSpeed} m/s (${windDirection})[/]`;
			});

			for (let i = 0; i <= 7; i++) {
				const year = this.firstWeekDay.getFullYear();
				const month = this.firstWeekDay.getMonth();
				const date = this.firstWeekDay.getDate() + i;
				this.createRangeGrid(new Date(year, month, date, 2), 1, 2, '02:00');
				this.createRangeGrid(new Date(year, month, date, 8), 0.3, 1, '08:00');
				this.createRangeGrid(new Date(year, month, date, 14), 0.3, 1, '14:00');
				this.createRangeGrid(new Date(year, month, date, 20), 0.3, 1, '20:00');
			}

			const valueAxis = this.ganttChart.chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.min = this.quay.initbolcode;
			valueAxis.max = this.quay.endbolcode;
			valueAxis.marginRight = 30;

			valueAxis.renderer.opposite = true;
			valueAxis.renderer.inversed = false; //(this.quay.invertbollards === 1);
			valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
			valueAxis.renderer.grid.template.strokeOpacity = 1;
			valueAxis.renderer.gridContainer.background.fill = am4core.color('#000');
			valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
			valueAxis.renderer.minGridDistance = 25;
			//valueAxis.title.fontWeight = "bolder";
			valueAxis.cursorTooltipEnabled = false;

			const series = this.ganttChart.chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.openValueX = 'bollardini';
			series.dataFields.valueX = 'bollardend';
			series.dataFields.openDateY = 'etaplanner';
			series.dataFields.dateY = 'etdplanner';
			series.name = 'berths';
			series.data = data;
			series.strokeWidth = 2;
			series.xAxis = valueAxis;
			series.columns.template.propertyFields.fill = 'color'; // get color from data
			series.columns.template.propertyFields.stroke = 'color';
			series.columns.template.strokeOpacity = 1;
			series.columns.template.column.cornerRadiusTopLeft = 3;
			series.columns.template.column.cornerRadiusTopRight = 3;
			series.columns.template.column.cornerRadiusBottomLeft = 3;
			series.columns.template.column.cornerRadiusBottomRight = 3;
			series.columns.template.column.stroke = am4core.color('#fff');
			series.columns.template.column.strokeWidth = 2;
			series.resizable = true;

			function leftRadius(radius, target) {
				if (
					self.quay.seaside === 'L' &&
					(target.dataItem.dataContext.berthingposition === 'DOCKED-PORT' ||
						target.dataItem.dataContext.berthingposition === 'ALONGSIDE-PORT')
				) {
					// BABOR
					return 25;
				}
				if (
					self.quay.seaside === 'R' &&
					target.dataItem.dataContext.berthingposition !== 'DOCKED-PORT' &&
					target.dataItem.dataContext.berthingposition !== 'ALONGSIDE-PORT'
				) {
					// BABOR
					return 25;
				}
				return radius;
			}
			function rightRadius(radius, target) {
				if (
					self.quay.seaside === 'R' &&
					(target.dataItem.dataContext.berthingposition === 'DOCKED-PORT' ||
						target.dataItem.dataContext.berthingposition === 'ALONGSIDE-PORT')
				) {
					// BABOR
					return 25;
				}
				if (
					self.quay.seaside === 'L' &&
					target.dataItem.dataContext.berthingposition !== 'DOCKED-PORT' &&
					target.dataItem.dataContext.berthingposition !== 'ALONGSIDE-PORT'
				) {
					// BABOR
					return 25;
				}
				return radius;
			}
			series.columns.template.column.adapter.add('cornerRadiusTopLeft', leftRadius);
			series.columns.template.column.adapter.add('cornerRadiusTopRight', rightRadius);
			series.columns.template.column.adapter.add('cornerRadiusBottomLeft', leftRadius);
			series.columns.template.column.adapter.add('cornerRadiusBottomRight', rightRadius);
			series.columns.template.column.adapter.add('stroke', function (stroke, target) {
				if (target.dataItem.dataContext.berthingposition && target.dataItem.dataContext.berthingposition.startsWith('ALONGSIDE')) {
					return '#ff0000';
				}
				return stroke;
			});

			this.createLabelBullet(series, "{openDateY.formatDate('HH:mm')}", 0.95, 'top', 'left', 1);
			this.createLabelBullet(series, "{etc.formatDate('HH:mm')}", 0.95, 'middle', 'left', 2);
			this.createLabelBullet(series, "{dateY.formatDate('HH:mm')}", 0.95, 'bottom', 'left', 3);
			this.createLabelBullet(series, "{rts.formatDate('HH:mm')}", 0.5, 'top', 'middle', 1);
			this.createLabelBullet(series, '[bold]{vesselname} ({portcallnumber})[/]', 0.5, 'middle', 'middle', 2);
			this.createLabelBullet(series, '{delayed}', 0.05, 'top', 'right', 1);
			this.createLabelBullet(series, '{bollardini} - {bollardend}', 0.05, 'bottom', 'right', 3);
			this.createImageBullet(series);

			series.columns.template.events.on('sizechanged', function (ev) {
				if (ev.target.dataItem && ev.target.dataItem.bullets) {
					var height = ev.target.pixelHeight;
					var width = ev.target.pixelWidth;
					ev.target.dataItem.bullets.each(function (id, bullet) {
						if (bullet.label) {
							if (height > 45 && width > 250) {
								bullet.show();
							} else if (bullet.label.verticalCenter === 'middle' && bullet.label.horizontalCenter === 'middle') {
								bullet.show();
							} else {
								bullet.hide();
							}
						} else {
							bullet.show();
						}
					});
				}
			});

			// Vessel click
			series.columns.template.events.on('hit', function (event) {
				const data = event.target.dataItem.dataContext;
				self.vesselDialog = true;
				self.vessel = data;
			});
			/*
			// Add a single HTML-based tooltip
			series.columns.template.column.tooltipText = `[bold]{vesselname}[/]
			----
			ETA: {openDateY.formatDate('HH:mm')}
			ETD: {dateY.formatDate('HH:mm')}
			ETC: {etc.formatDate('HH:mm')}

			RTS: {rts.formatDate('dd:MM:yyyy HH:mm')}
			Bollards: {bollardini} - {bollardend}
			Arrival IDLE time: {arrivalidletime}
			Departure IDLE time: {departureidletime}`;
/*
			series.columns.template.column.tooltipHTML = `<h2>{vesselname}</h2>
			<table>
				<tr>
					<td>ETA:</td><td>{openDateY.formatDate('HH:mm')}</td>
				</tr>
				<tr>
					<td>ETD:</td><td>{dateY.formatDate('HH:mm')}</td>
				</tr>
			</table>`;
*/
			this.ganttChart.berthSeries = series;
			this.ganttChart.berthValueAxis = valueAxis;

			// Scrollbars
			//this.ganttChart.chart.scrollbarX = new am4core.Scrollbar();
			//this.ganttChart.chart.scrollbarY = new am4core.Scrollbar();

			// Cursor
			this.ganttChart.chart.cursor = new am4charts.XYCursor();
			this.ganttChart.chart.cursor.behavior = 'zoomY';
			this.ganttChart.chart.cursor.lineX.disabled = true;
			this.ganttChart.chart.cursor.lineY.disabled = true;
			this.ganttChart.dateAxis.cursorTooltipEnabled = false;

			// Enable export
			this.ganttChart.chart.exporting.menu = new am4core.ExportMenu();
			this.ganttChart.chart.exporting.filePrefix = 'Berth_Plan_' + this.convertDatetimeToString(new Date());
			//this.ganttChart.chart.scrollbarY.exportable = false;
			this.ganttChart.chart.exporting.useWebFonts = false;
			this.ganttChart.chart.exporting.menu.items[0].icon =
				'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';

			// Now line
			this.range = this.ganttChart.dateAxis.axisRanges.create();
			this.range.date = this.now;
			this.range.grid.stroke = am4core.color('#ff0000');
			this.range.grid.strokeWidth = 1.5;
			this.range.grid.strokeOpacity = 1;
			this.range.bullet = new am4core.Triangle();
			this.range.bullet.width = 15;
			this.range.bullet.height = 11;
			this.range.bullet.paddingTop = -65;
			this.range.bullet.fill = am4core.color('#c00');
			this.range.bullet.horizontalCenter = 'middle';
			this.range.bullet.rotation = 90;
			this.range.grid.above = true;
		},
		createRangeGrid(date, strokeOpacity, strokeWidth, text) {
			const range = this.ganttChart.dateAxis.axisRanges.create();
			range.date = date;
			range.grid.strokeOpacity = strokeOpacity;
			range.grid.strokeWidth = strokeWidth;
			range.grid.stroke = '#336799';
			range.tick.disabled = false;
			range.tick.strokeOpacity = 1;
			range.tick.length = 0;
			range.label.text = text;
			range.label.paddingRight = -50;
			range.label.fontWeight = 'normal';
		},
		createLabelBullet(series, text, locationX, vCenter, hCenter, position) {
			const labelBullet = series.bullets.push(new am4charts.LabelBullet());
			labelBullet.label.text = text;
			labelBullet.label.align = 'middle';
			labelBullet.label.textAlign = 'center';
			labelBullet.locationX = locationX;
			labelBullet.label.adapter.add('verticalCenter', () => {
				return vCenter;
			});
			labelBullet.label.adapter.add('horizontalCenter', () => {
				return hCenter;
			});
			labelBullet.label.adapter.add('dy', (dy, target) => {
				switch (position) {
					case 1:
						return target.dataItem.itemHeight * 0.05;
					case 2:
						return target.dataItem.itemHeight / 2;
					case 3:
						return target.dataItem.itemHeight * 0.95;
					default:
						break;
				}
			});
			labelBullet.interactionsEnabled = false;
			labelBullet.hoverable = false;
		},
		createImageBullet(series) {
			const bullet = series.bullets.push(new am4charts.Bullet());
			const image = bullet.createChild(am4core.Image);
			image.href = require('@/assets/images/exclamation_circle.svg');
			image.width = 16;
			image.height = 16;
			image.verticalCenter = 'top';
			image.horizontalCenter = 'right';

			bullet.propertyFields.disabled = 'lessthantwentyfourtoetaNegated';
		},
		convertDatetimeToString(date) {
			function pad(s) {
				return s < 10 ? '0' + s : s;
			}
			return (
				[pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join('/') +
				'_' +
				[pad(date.getHours()), pad(date.getMinutes()), pad(date.getSeconds())].join('_')
			);
		},
		getForecastInfo(date) {
			if (!this.dailyForecast || !Array.isArray(this.dailyForecast)) {
				console.error('Not daily forecast!');
				return null;
			}

			for (let i = 0, dailyForecastLength = this.dailyForecast.length; i < dailyForecastLength; i++) {
				const dayForecast = this.dailyForecast[i];
				const dateForecast = new Date(dayForecast.dt * 1000);
				if (
					date.getDate() === dateForecast.getDate() &&
					date.getMonth() === dateForecast.getMonth() &&
					date.getFullYear() === dateForecast.getFullYear()
				) {
					return dayForecast;
				}
			}

			return null;
		},
		getWindDirectionFromWindDegrees(degrees) {
			// http://snowfence.umn.edu/Components/winddirectionanddegrees.htm
			if (degrees > 348.75 || degrees <= 11.25) {
				return 'N';
			} else if (degrees > 11.25 && degrees <= 33.75) {
				return 'NNE';
			} else if (degrees > 33.75 && degrees <= 56.25) {
				return 'NE';
			} else if (degrees > 56.25 && degrees <= 78.75) {
				return 'ENE';
			} else if (degrees > 78.75 && degrees <= 101.25) {
				return 'E';
			} else if (degrees > 101.25 && degrees <= 123.75) {
				return 'ESE';
			} else if (degrees > 123.75 && degrees <= 146.25) {
				return 'SE';
			} else if (degrees > 146.25 && degrees <= 168.75) {
				return 'SSE';
			} else if (degrees > 168.75 && degrees <= 191.25) {
				return 'S';
			} else if (degrees > 191.25 && degrees <= 213.75) {
				return 'SSW';
			} else if (degrees > 213.75 && degrees <= 236.25) {
				return 'SW';
			} else if (degrees > 236.25 && degrees <= 258.75) {
				return 'WSW';
			} else if (degrees > 258.75 && degrees <= 281.25) {
				return 'W';
			} else if (degrees > 281.25 && degrees <= 303.75) {
				return 'WNW';
			} else if (degrees > 303.75 && degrees <= 326.25) {
				return 'NW';
			} else if (degrees > 326.25 && degrees <= 348.75) {
				return 'NNW';
			}
		}
	}
};
</script>
