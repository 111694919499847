<template>
	<v-dialog v-model="visible" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.berthblock') }}</span>
				<v-spacer />
				<v-btn style="min-width: 32px" small depressed @click="visible = false">
					<i class="far fa-times"></i>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3">
				<v-form class="pt-3" ref="form" lazy-validation @submit.prevent>
					<v-row class="pui-form-layout">
						<v-flex xs12>
							<!-- LOCATIONID -->
							<span>{{ $t('stopblock.locationid') }}</span>
							<v-autocomplete
								:id="`locationid-stopblock`"
								v-model="model.locationid"
								:placeholder="$t('stopblock.locationid')"
								required
								toplabel
								:items="$store.getters.getRawPortBerths"
								item-value="id"
								item-text="name"
								return-value
								solo
								flat
								hide-details
								@change="updateBollards(model.locationid)"
							></v-autocomplete>
						</v-flex>
						<v-flex xs12>
							<!-- DATEINI -->
							<pui-date-field
								:id="`dateini-stopblock`"
								v-model="model.dateini"
								:max="model.dateend"
								:label="$t('stopblock.dateini')"
								required
								toplabel
								time
							></pui-date-field>

							<pui-date-field
								:id="`dateend-stopblock`"
								v-model="model.dateend"
								:label="$t('stopblock.dateend')"
								:min="model.dateini"
								required
								toplabel
								time
							></pui-date-field>
						</v-flex>

						<v-flex xs12>
							<v-row dense v-if="enableBollards">
								<v-col>
									<pui-select
										:id="`bollardini-stopblock`"
										v-model="model"
										:label="$t('stopblock.bollardini')"
										modelName="bollard"
										toplabel
										:modelFormMapping="{ id: 'bollardini' }"
										:itemsToSelect="[{ id: model.bollardini }]"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthFilter"
										:order="{ orderby: 'asc' }"
									></pui-select>
								</v-col>
								<v-col>
									<pui-select
										:id="`bollardend-stopblock`"
										v-model="model"
										:label="$t('stopblock.bollardend')"
										modelName="bollard"
										toplabel
										:modelFormMapping="{ id: 'bollardend' }"
										:itemsToSelect="[{ id: model.bollardend }]"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthFilter"
										:order="{ orderby: 'asc' }"
									></pui-select>
								</v-col>
							</v-row>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:id="`name-stopblock`"
								v-model="model.name"
								:label="$t('stopblock.name')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-flex>
					</v-row>
				</v-form>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-icon color="red accent-4" @click="deleteBlock()" v-if="model.idblock">fas fa-trash-alt</v-icon>
				<v-spacer></v-spacer>
				<v-btn text @click="visible = false">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" text @click="save()">{{ $t('form.save') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		vessel: {
			type: Object,
			default() {
				return {};
			},
			required: false
		},
		quay: {
			default() {
				return {};
			},
			required: false
		}
	},
	data() {
		return {
			visible: false,
			modelName: 'stopblock',
			modelConfig: null,
			visibleColumns: ['locationid', 'dateini', 'dateend', 'bollardini', 'bollardend', 'name'],
			modelColumnDefs: [],
			dateTimeColumnIndexes: [],
			columns: [],
			model: {},
			berthFilter: null,
			enableBollards: false
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.modelConfig = this.$store.getters.getModelByName(this.modelName);
			this.fillBlockDataFromVessel(this.model);
		},
		visible() {
			this.fillBlockDataFromVessel(this.vessel);
			console.log(this.vessel);
		}
	},
	mounted() {
		this.modelConfig = this.$store.getters.getModelByName(this.modelName);
		this.fillBlockDataFromVessel(this.model);
	},
	methods: {
		save() {
			if (this.model.idblock) {
				this.update();
			} else {
				this.insert();
			}
			this.visible = false;
			this.$puiEvents.$emit('operationscountdown_reload');
		},
		deleteBlock() {
			this.remove();
			this.visible = false;
			this.$puiEvents.$emit('operationscountdown_reload');
		},
		insert() {
			this.$puiRequests.postRequest(
				'/berthblock/insert',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		update() {
			this.model.id = this.model.idblock;
			this.$puiRequests.putRequest(
				'/berthblock/update',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		remove() {
			const params = {
				id: this.model.idblock
			};
			this.$puiRequests.deleteRequest(
				'/berthblock/delete',
				this.model.idblock,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				params
			);
		},
		fillBlockDataFromVessel(vessel) {
			if (vessel.visitid) {
				this.model.locationid = vessel.berthid;
				this.model.dateini = vessel.etaplanner;
				this.model.dateend = vessel.etdplanner;
				this.model.bollardini = vessel.bollardiniid;
				this.model.bollardend = vessel.bollardendid;
				this.model.idblock = vessel.visitid - 55000;
				console.log(this.model.idblock);
				this.model.name = vessel.vesselname;
				//this.updateMaxBollards(this.findBerthDefinition(vessel.berthid));

				this.berthFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'berth_id', op: 'eq', data: this.model.locationid }]
				};

				this.getBollardsFromBerth(this.model.locationid);
			} else {
				this.model.locationid = this.quay.id;
				this.updateBollards(this.model.locationid);
			}
		},
		findBerthDefinition(locationid) {
			let userBerths = this.$store.getters.getRawPortBerths;
			return userBerths.find((b) => b.id === parseInt(locationid));
		},
		updateBollards(locationid) {
			this.berthFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berth_id', op: 'eq', data: locationid }]
			};
			this.model.bollardini = null;
			this.model.bollardend = null;
			this.getBollardsFromBerth(locationid);
		},
		updateMaxBollards(berth) {
			this.model.maxbollardini = berth.initbol;
			this.model.maxbollardend = berth.endbol;
		},
		getBollardsFromBerth(locationid) {
			const opts = {
				model: 'berth',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: locationid
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0].initbol && response.data.data[0].endbol) {
					this.enableBollards = true;
				} else {
					this.enableBollards = false;
				}
			});
		}
	}
};
</script>
