import TileLayer from 'ol/layer/Tile';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import OSM from 'ol/source/OSM';
import { get as getProjection } from 'ol/proj.js';
import { getTopLeft, getWidth } from 'ol/extent.js';
import { BASEMAP_ID } from '@/components/operations/map/constants/layers';
import Attribution from 'ol/control/Attribution';

import Icons from '@/components/icon/icon-mappings';

export default {
	computed: {
		initialBaseLayerMap() {
			return this.baseTileLayerOSM;
		},

		baseTileLayerOSM() {
			return new TileLayer({
				id: BASEMAP_ID,
				source: new OSM() // tiles are served by OpenStreetMap
			});
		},

		pnoaLayer() {
			var epsg3857 = 'EPSG:3857';
			var projection = getProjection(epsg3857);
			var projectionExtent = projection.getExtent();
			const size = getWidth(projectionExtent) / 256;
			var resolutions = new Array(18);
			var matrixIds = new Array(18);
			for (var z = 0; z < 18; ++z) {
				// generate resolutions and matrixIds arrays for this WMTS
				resolutions[z] = size / Math.pow(2, z);
				matrixIds[z] = z;
			}
			var attribution = new Attribution({
				html: 'Teselas de PNOA cedido por © Instituto Geográfico Nacional de España'
			});

			const pnoa_source = new WMTS({
				url: 'http://www.ign.es/wmts/pnoa-ma',
				layer: 'OI.OrthoimageCoverage',
				//matrixSet: 'EPSG:4326',
				matrixSet: epsg3857,
				format: 'image/png',
				projection: projection,
				tileGrid: new WMTSTileGrid({
					origin: getTopLeft(projectionExtent),
					resolutions: resolutions,
					matrixIds: matrixIds
				}),
				style: 'normal',
				attributions: attribution
			});

			return new TileLayer({
				id: BASEMAP_ID,
				source: pnoa_source
			});
		},

		baseLayersControl() {
			return [
				{
					title: this.$t('operations.baselayers.osm'),
					layer: this.baseTileLayerOSM,
					src: Icons['baselayers']['osm']
				},
				{
					title: this.$t('operations.baselayers.satellite'),
					layer: this.pnoaLayer,
					src: Icons['baselayers']['satellite']
				}
			];
		}
	}
};
