import { Fill, Style, Stroke } from 'ol/style';
import Text from 'ol/style/Text';
import colorUtils from './colorUtils';

const aisStyleFunction = function (feature, resolution) {
	return new Style({
		stroke: new Stroke({
			color: createStrokeStyle(feature),
			width: 0.75
		}),
		fill: createFillStyle(feature),
		text: feature.get('featureVisible') ? createTextStyle(feature, resolution) : null
	});
};

const getVesselText = function (feature, resolution) {
	let text = '';
	if (resolution < 2) {
		text = feature.get('vesselname');
	}
	return text;
};

const createTextStyle = function (feature, resolution) {
	const rotation = feature.get('alfa') > Math.PI / 2 && feature.get('alfa') < Math.PI ? feature.get('alfa') + Math.PI : feature.get('alfa');
	return new Text({
		text: getVesselText(feature, resolution),
		rotation,
		stroke: new Stroke({
			color: 'white',
			width: 6
		}),
		overflow: true
	});
};

const createStrokeStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return 'rgba(0, 0, 0, 0)';
	}
	return colorUtils.getColorOutlineOperations(feature);
};

const createFillStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return new Fill({
			color: 'rgba(0, 0, 0, 0)'
		});
	}
	return new Fill({
		color: colorUtils.getColorOperations(feature)
	});
};

export default aisStyleFunction;
