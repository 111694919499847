<template>
	<table>
		<tr data-test="vessel-name">
			<td colspan="2" style="text-align: center;">
				<b>{{ vesselnameportcallnumber }}</b>
			</td>
		</tr>
		<tr data-test="vessel-eta">
			<td><b>{{ $t('berthplanner.vessel.eta.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'etalocal') }}</td>
		</tr>
		<tr data-test="vessel-etd">
			<td><b>{{ $t('berthplanner.vessel.etd.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'etdlocal') }}</td>
		</tr>
		<tr data-test="vessel-etc">
			<td><b>{{ $t('berthplanner.vessel.etc.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'etclocal') }}</td>
		</tr>
		<tr data-test="vessel-ata">
			<td><b>{{ $t('berthplanner.vessel.ata.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'atalocal') }}</td>
		</tr>
		<tr data-test="vessel-atd">
			<td><b>{{ $t('berthplanner.vessel.atd.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'atdlocal') }}</td>
		</tr>
		<tr data-test="vessel-atc">
			<td><b>{{ $t('berthplanner.vessel.atc.tooltip') }}:</b></td>
			<td style="text-align: right;">{{ getDateFormatted(stop, 'atclocal') }}</td>
		</tr>
		<tr data-test="vessel-arrivalidletime">
			<td><b>{{ $t('berthplanner.vessel.arrivalidletime') }}:</b></td>
			<td style="text-align: right;">{{ getArrivalIdleTime(stop) }}</td>
		</tr>
		<tr data-test="vessel-departureidletime">
			<td><b>{{ $t('berthplanner.vessel.departureidletime') }}:</b></td>
			<td style="text-align: right;">{{ getDepartureIdleTime(stop) }}</td>
		</tr>
		<tr data-test="vessel-bollards">
			<td><b>{{ $t('berthplanner.vessel.bollards') }}:</b></td>
			<td style="text-align: right;">{{ getBollards(stop) }}</td>
		</tr>
	</table>
</template>

<script>
import BerthPlannerMixin from '@/mixins/BerthPlannerMixin';

export default {
	mixins: [BerthPlannerMixin],
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	computed: {
		vesselnameportcallnumber() {
			return (this.stop.vesselname && this.stop.portcallnumber) ?
				`${this.stop.vesselname} (${this.stop.portcallnumber})` : this.stop.vesselname;
		}
	},
	methods: {
		getDateFormatted(stop, property) {
			return stop[property] ? this.dateFormat(stop[property], this.dateTimeFormat) : this.$t('berthplanner.vessel.etc.waiting');
		},
		getArrivalIdleTime(stop) {
			return stop.arrivalidletime ? stop.arrivalidletime : this.$t('berthplanner.vessel.idletime.notavailable');
		},
		getDepartureIdleTime(stop) {
			return stop.departureidletime ? stop.departureidletime: this.$t('berthplanner.vessel.idletime.notavailable');
		},
		getBollards(stop) {
			return stop.bollardini + " - " + stop.bollardend;
		}
	}
}
</script>
