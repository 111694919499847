<template>
	<div class="operations-legend__block">
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<div v-on="on" v-bind="attrs">
					<div class="circle" :style="{ background: realColor }" />
					<div>{{ title }}</div>
				</div>
			</template>
			<span>{{ tooltip }}</span>
		</v-tooltip>
	</div>
</template>

<script>
export default {
	props: ['title', 'tooltip', 'realColor']
};
</script>
