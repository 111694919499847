import {
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	AIS_VESSEL_GROUP_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';

export default {
	computed: {
		layerStopAcceptedActive() {
			return this.$store.getters.previsionGroupLayers[STOP_ACCEPTED_LAYER_ID];
		},

		layerStopPlannedActive() {
			return this.$store.getters.previsionGroupLayers[STOP_PLANNED_LAYER_ID];
		},

		layerStopAuthorizedActive() {
			return this.$store.getters.previsionGroupLayers[STOP_AUTHORIZED_LAYER_ID];
		},

		layerStopConfirmedActive() {
			return this.$store.getters.previsionGroupLayers[STOP_CONFIRMED_LAYER_ID];
		},

		layerStopInitiatedActive() {
			return this.$store.getters.previsionGroupLayers[STOP_INITIATED_LAYER_ID];
		},

		layerAISActive() {
			return this.$store.getters.layers[AIS_VESSEL_GROUP_ID].active;
		},

		layerAISVisitFilterActive() {
			return this.$store.getters.aisGroupLayers[FILTER_AIS_VISIT_LAYER_ID];
		},

		layerAISServiceFilterActive() {
			return this.$store.getters.aisGroupLayers[FILTER_SERVICE_LAYER_ID];
		},

		layerAISOtherFilterActive() {
			return this.$store.getters.aisGroupLayers[FILTER_OTHER_VESSEL_LAYER_ID];
		},

		layersAisPanelActive() {
			if (
				!this.layerStopAcceptedActive &&
				!this.layerStopPlannedActive &&
				!this.layerStopAuthorizedActive &&
				!this.layerStopConfirmedActive &&
				!this.layerStopInitiatedActive &&
				!this.layerAISActive
			) {
				return false;
			}
			return true;
		},

		layerZonesActive() {
			return this.$store.getters.layers[CEP_ZONES_ID].active;
		}
	}
};
