<template>
	<div class="operations-legend">
		<v-layout justify-center>
			<berth-planner-legend-block
				v-for="(item, index) in items"
				v-show="getColor(item)"
				:key="index"
				:data-test="item"
				:title="getTitle(item)"
				:tooltip="getTooltip(item)"
				:realColor="getColor(item)"
			/>
		</v-layout>
	</div>
</template>

<script>
import BerthPlannerLegendBlock from './BerthPlannerLegendBlock.vue';

export default {
	components: { BerthPlannerLegendBlock },
	props: {
		items: {
			type: Array,
			required: true
		}
	},
	methods: {
		getTitle(item) {
			return this.$t(`stop.status.${item}.title`);
		},
		getTooltip(item) {
			return this.$t(`stop.status.${item}.tooltip`);
		},
		getColor(item) {
			if (item === 'not_registered') {
				return this.$store.state.berthplanner.unknownColor;
			}
			return this.$store.state.berthplanner[`${item}Color`];
		}
	}
};
</script>
