<template>
	<berth-planner-container-pro v-if="$store.getters.isBerthPlannerPro" />
	<berth-planner-container-lite v-else />
</template>

<script>
import BerthPlannerContainerPro from './BerthPlannerContainerPro.vue';
import BerthPlannerContainerLite from './BerthPlannerContainerLite.vue';

export default {
	components: { BerthPlannerContainerPro, BerthPlannerContainerLite }
};
</script>
