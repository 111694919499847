import BerthPlannerToolbar from '@/components/berthplanner/BerthPlannerToolbar.vue';
import BerthPlannerPanel from '@/components/berthplanner/BerthPlannerPanel.vue';
import BerthPlannerDiagram from '@/components/berthplanner/BerthPlannerDiagram.vue';
import BerthPlannerLegend from '@/components/berthplanner/BerthPlannerLegend.vue';
import OperationsCountdown from '@/components/OperationsCountdown.vue';
import CartoMixin from '@/mixins/CartoMixin.js';

import FilterService from '@/services/filter.service.js';
import WeatherService from '@/services/weather.service.js';

export default {
	components: { BerthPlannerToolbar, BerthPlannerPanel, BerthPlannerDiagram, BerthPlannerLegend, OperationsCountdown },
	mixins: [CartoMixin],
	data() {
		return {
			firstWeekDay: null,
			lastWeekDay: null,
			dailyForecast: [],
			status: this.$store.state.berthplanner.defaultStatus,
			quay: this.$store.getters.getDefaultQuayByTerminal,
			stopsLoaded: null,
			stops: [],
			modelName: 'berthplanner',
			statusItems: this.getStatusItems(),
			legendItems: this.getStatusPALegend(),
			portLatitude: null,
			portLongitude: null
		};
	},
	watch: {
		stopsLoaded(loaded) {
			this.$store.state.global.puiloader.show = !loaded;
		}
	},
	mounted() {
		this.stopsLoaded = false;
		this.setAdditionalStatus();
		this.getDailyForecastFor7Days();

		this.$puiEvents.$on('berthplanner-toolbar_dateSelected', (dateSelected) => {
			this.getWeekInfo(dateSelected);
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_searchingText', (searching) => {
			this.searchText = searching;
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_statusSelected', (statusSelected) => {
			this.status = statusSelected;
			this.setAdditionalStatus();
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_quaySelected', (quaySelected) => {
			this.quay = quaySelected;
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_showBerths1To26Bollards', (showAllBerths) => {
			this.showAllBerths = showAllBerths;
			this.getStops();
		});
		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getStops();
		});
	},
	destroyed() {
		this.$puiEvents.$off('berthplanner-toolbar_dateSelected');
		this.$puiEvents.$off('berthplanner-toolbar_searchingText');
		this.$puiEvents.$off('berthplanner-toolbar_statusSelected');
		this.$puiEvents.$off('berthplanner-toolbar_quaySelected');
		this.$puiEvents.$off('operationscountdown_reload');
	},
	methods: {
		setAdditionalStatus() {
			// do nothing
		},
		getWeekInfo(date) {
			this.firstWeekDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
			this.lastWeekDay = new Date(this.firstWeekDay.getFullYear(), this.firstWeekDay.getMonth(), this.firstWeekDay.getDate() + 7);
		},
		async getDailyForecastFor7Days() {
			const portId = window.localStorage.workingPortsId;
			if (portId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: portId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, async (response) => {
					if (response.data.data[0].latitude && response.data.data[0].longitude) {
						this.portLatitude = response.data.data[0].latitude;
						this.portLongitude = response.data.data[0].longitude;
						this.dailyForecast = await WeatherService.getDailyForecastFor7Days(this.portLatitude, this.portLongitude);
					}
				});
			}
		},
		getStops() {
			this.stopsLoaded = false;

			const params = this.getParams();
			this.$puiRequests.postRequest(
				'/vstopberthplanner/getList',
				params,
				(response) => {
					try {
						this.fillStopsFromResponse(response);
						this.stopsLoaded = true;
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.stopsLoaded = true;
				}
			);
		},
		convertDateToString(date) {
			const addZero = function (num) {
				return num < 10 ? '0' + num : num;
			};
			return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
		},
		getParams() {
			const firstDate = this.convertDateToString(this.firstWeekDay);
			const lastDate = this.convertDateToString(this.lastWeekDay);
			const locationIds = this.quay ? (this.quay.groupIds ? this.quay.groupIds : [this.quay.id]) : undefined;
			const portId = this.quay ? this.quay.portid : undefined;

			const filter = {
				groupOp: 'and',
				groups: FilterService.getBerthPlannerFilterGroups(firstDate, lastDate, locationIds, portId, this.statusid),
				rules: []
			};
			const params = {
				model: this.modelName,
				page: 1,
				queryLang: 'es',
				rows: 100,
				filter: filter
			};
			if (this.orderingBy) {
				params.order = [{ column: this.orderingBy, direction: 'asc' }];
			}
			if (this.searchText) {
				params.queryText = this.searchText;
				params.queryFields = ['vesselname', 'portcallnumber'];
			}

			return params;
		},
		fillStopsFromResponse(response) {
			this.stops = [];

			var self = this;
			const setStopColor = function (stop) {
				if (stop.statusid === 'RQ') {
					stop.color = self.$store.state.berthplanner.rqColor;
				} else if (stop.statusid === 'IN') {
					stop.color = self.$store.state.berthplanner.inColor;
				} else if (stop.statusid === 'AC') {
					stop.color = self.$store.state.berthplanner.acColor;
				} else if (stop.statusid === 'CO') {
					stop.color = self.$store.state.berthplanner.coColor;
				} else if (stop.statusid === 'AU') {
					stop.color = self.$store.state.berthplanner.auColor;
				} else if (stop.statusid === 'FI' || stop.statusid === 'IV' || stop.statusid === 'IG') {
					stop.color = self.$store.state.berthplanner.fiColor;
				} else if (stop.statusid === 'SO') {
					stop.color = self.$store.state.berthplanner.cargooperColor;
				} else if (stop.statusid === 'EO') {
					stop.color = self.$store.state.berthplanner.compcargooperColor;
				} else if (stop.statusid === 'BERTH_BLOCK') {
					stop.color = self.$store.state.berthplanner.berthblockColor;
				} else {
					stop.color = self.$store.state.berthplanner.unknownColor;
					stop.opacity = 0.9;
				}
			};

			if (response.data && response.data.data && response.data.data.length > 0) {
				for (let i = 0, length = response.data.data.length; i < length; i++) {
					const stop = response.data.data[i];

					stop.idstop = parseInt(stop.id);
					stop.bollardini = parseFloat(stop.bollardini);
					stop.bollardend = parseFloat(stop.bollardend);
					// FIXME: remove this code. Hide stops from 1 to 26 bollards in MUESTE for testing...
					if (
						stop.status !== 'BERTH_BLOCK' &&
						((!this.showAllBerths && stop.berthcode === 'MUESTE' && stop.bollardini == 26 && stop.bollardend == 1) ||
							(stop.berthcode === 'MUESTE' && stop.bollardini == 1 && stop.bollardend == 26))
					) {
						continue;
					}
					// FIXME: only for testing by developers
					if (stop.statusid === 'NR' && !this.$store.getters.hasFunctionality('VIEW_BERTHPLANNER_NOTREGISTERED')) {
						continue;
					}
					stop.delayed = stop.delayeta || stop.delayetc ? 'DELAYED' : undefined;
					stop.lessthantwentyfourtoetaNegated = !stop.lessthantwentyfourtoeta;
					stop.etaplannerlocal = new Date(stop.eta);
					stop.etdplannerlocal = new Date(stop.etd);
					stop.etalocal = stop.etaterminal ? new Date(stop.etaterminal) : stop.eta ? new Date(stop.eta) : undefined;
					stop.etdlocal = stop.etdterminal ? new Date(stop.etdterminal) : stop.etd ? new Date(stop.etd) : undefined;
					stop.etclocal = stop.etc ? new Date(stop.etc) : undefined;
					stop.rtslocal = stop.rts ? new Date(stop.rts) : undefined;
					stop.atalocal = stop.ataterminal ? new Date(stop.ataterminal) : stop.ata ? new Date(stop.ata) : undefined;
					stop.atdlocal = stop.atdterminal ? new Date(stop.atdterminal) : stop.atd ? new Date(stop.atd) : undefined;
					stop.atclocal = stop.atc ? new Date(stop.atc) : undefined;
					stop.ninetyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -90);
					stop.sixtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -60);
					stop.thirtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -30);
					if (stop.ninetyminstoetc || stop.sixtyminstoetc || stop.thirtyminstoetc) {
						console.log('Alert: ETC near to reach', stop.vesselname, stop.ninetyminstoetc, stop.sixtyminstoetc, stop.thirtyminstoetc);
					}
					setStopColor(stop);

					self.stops.push(stop);
				}
			}
		},
		isStopEtcMinusXMinutesLowerThanNow(stop, xMinutes) {
			if (!stop || !stop.etc || stop.status !== 'INITIATED' || stop.atc) {
				return false;
			}
			const now = new Date();
			const etc = new Date(stop.etc);
			if (etc.getTime() < now.getTime()) {
				return false;
			}
			const etcMinusXMinutes = new Date(etc.getTime() + xMinutes * 60000);
			return etcMinusXMinutes.getTime() <= now.getTime();
		},
		getStatusItems() {
			let status = [];
			const stats = JSON.parse(window.localStorage.getItem('workingStopStatus'));
			if (stats) {
				stats.forEach((element) => {
					let code = element.stopstatuscode.toLowerCase();
					status.push({ id: code, description: this.$t(`stop.status.${code}.title`) });
				});
			}
			return status;
		},
		getStatusPALegend() {
			let status = [];
			console.log(this.$store.getters.stopsStatusValues);
			console.log(JSON.parse(window.localStorage.getItem('workingStopStatus')));
			const stats = JSON.parse(window.localStorage.getItem('workingStopStatus'));
			if (stats) {
				stats.forEach((element) => {
					console.log(element);
					let code = element.stopstatuscode.toLowerCase();
					console.log(code);
					if (element.visibleberthingplan) {
						status.push('' + code);
					}
				});
			}
			return status;
		}
	}
};
