<template>
	<v-layout align-center wrap class="berthplanner-toolbar">
		<div>
			<v-btn text small @click.native="decrementDate">
				<v-icon>far fa-chevron-left</v-icon>
			</v-btn>
		</div>
		<v-flex xs10 md2 lg1>
			<v-menu
				v-model="menuDate"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="290px"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="computedDateFormatted"
						v-on="on"
						v-bind="attrs"
						prepend-inner-icon="far fa-calendar"
						readonly
						solo
						flat
						hide-details
						class="date-field"
					></v-text-field>
				</template>
				<v-date-picker v-model="currentDate" first-day-of-week="1" no-title @input="inputDatePicker()"></v-date-picker>
			</v-menu>
		</v-flex>
		<div>
			<v-btn text small @click.native="incrementDate">
				<v-icon>far fa-chevron-right</v-icon>
			</v-btn>
		</div>
		<v-flex xs12 md2 class="berthplanner-toolbar__field">
			<v-autocomplete
				v-model="currentQuay"
				:items="this.berths"
				item-value="id"
				item-text="name"
				return-object
				solo
				flat
				hide-details
				:placeholder="$t('berthplanner.quay')"
				:disabled="!(this.$store.getters.isPortAuthority || this.$store.getters.quayItems.length > 1 || this.$store.getters.isOperator)"
			></v-autocomplete>
		</v-flex>
		<v-flex xs12 md2 class="berthplanner-toolbar__field">
			<v-text-field
				:placeholder="$t('operationstoolbar.search')"
				v-model="searchingText"
				prepend-inner-icon="far fa-search"
				solo
				flat
				outlined
				hide-details
			></v-text-field>
		</v-flex>
		<v-flex xs12 md2>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" v-bind="attrs" v-on="on">{{ $t('berthplanner.additionalactions') }}</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-btn color="primary" text @click.stop="dialog = true">
							{{ $t('berthplanner.statusfilter') }}
						</v-btn>
						<v-dialog v-model="dialog" max-width="500">
							<v-card>
								<v-card-title class="text-h5">{{ $t('berthplanner.statusfilter') }}</v-card-title>
								<v-card-text>
									<v-checkbox
										v-for="(item, index) in statusItems"
										v-show="getColor(item.id)"
										:key="index"
										:label="item.description"
										:value="item.id"
										v-model="currentStatus"
									/>
								</v-card-text>
								<v-divider></v-divider>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="primary" text @click="dialog = false">{{ $t('pui9.accept') }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-list-item>
					<v-list-item v-if="this.$store.getters.isTerminalTTIA">
						<v-checkbox v-model="showBerths1To26Bollards" :label="$t('berthplanner.showberths1to26bollards')"></v-checkbox>
					</v-list-item>
					<v-list-item>
						<v-btn color="secondary" text @click.stop="$refs.berthPlannerWrongBollardsGrid.visible = true" :disabled="stops.length === 0">
							{{ $t('berthplanner.vesselsoutofrange') }}
						</v-btn>
						<berth-planner-wrong-bollards-dialog ref="berthPlannerWrongBollardsGrid" :stops="stops" />
					</v-list-item>
					<v-list-item v-if="this.$store.getters.editStopBlock">
						<v-btn color="primary" text @click.stop="$refs.berthPlannerBerthBlockForm.visible = true">
							{{ $t('berthplanner.berthblock') }}
						</v-btn>
						<berth-planner-berth-block-form :quay="currentQuay" ref="berthPlannerBerthBlockForm" />
					</v-list-item>
					<v-list-item>
						<v-btn color="primary" text @click.stop="$refs.berthplannerWeatherinfoDialog.visible = true" :disabled="!validDate()">
							{{ $t('berthplanner.weatherInfo') }}
						</v-btn>
						<berth-planner-weatherinfo-dialog ref="berthplannerWeatherinfoDialog" />
					</v-list-item>
				</v-list>
			</v-menu>
		</v-flex>
		<v-spacer></v-spacer>
		<operations-toolbar-info />
	</v-layout>
</template>

<script>
import BerthPlannerWrongBollardsDialog from './BerthPlannerWrongBollardsDialog.vue';
import BerthPlannerWeatherinfoDialog from './BerthPlannerWeatherinfoDialog';
import BerthPlannerBerthBlockForm from './BerthPlannerBerthBlockForm.vue';
import OperationsToolbarInfo from '@/components/OperationsToolbarInfo.vue';
import { trackEvent, trackDownload } from '@/plugins/matomo';

export default {
	components: { BerthPlannerWrongBollardsDialog, BerthPlannerBerthBlockForm, OperationsToolbarInfo, BerthPlannerWeatherinfoDialog },
	props: {
		statusItems: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			now: new Date(),
			currentDate: null,
			menuDate: false,
			searchingText: '',
			currentStatus: this.$store.state.berthplanner.defaultStatus,
			currentQuay: this.$store.getters.getDefaultQuayByTerminal,
			statusInitialLoad: true,
			showBerths1To26Bollards: false,
			stops: [],
			stopsKey: 1,
			dialog: false,
			dialogMeteo: false
		};
	},
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.currentDate);
		},
		currentStatusIcon() {
			if (!this.currentStatus || this.currentStatus.length === 0) {
				return 'far fa-battery-empty';
			} else if (this.currentStatus.length === 1) {
				return 'far fa-battery-quarter';
			} else if (this.currentStatus.length === 2) {
				return 'far fa-battery-half';
			} else {
				return 'far fa-battery-full';
			}
		},
		berths() {
			return this.$store.getters.quayItems.filter((item) => !item.isanchorage);
		}
	},
	watch: {
		currentDate() {
			this.$puiEvents.$emit('berthplanner-toolbar_dateSelected', this.now);
		},
		currentStatus() {
			if (this.statusInitialLoad) {
				this.statusInitialLoad = false;
				return;
			}
			clearTimeout(this.delayTimer);
			this.delayTimer = setTimeout(() => {
				console.log(this.currentStatus);

				this.$puiEvents.$emit('berthplanner-toolbar_statusSelected', [...this.currentStatus]);
			}, 500);
		},
		currentQuay() {
			console.log(this.currentQuay);
			trackEvent(
				'input',
				'dropdowm',
				'berth-planner-container-select-berth',
				'berth: ' + this.currentQuay.berthcode + ' id: ' + this.currentQuay.id
			);
			this.$puiEvents.$emit('berthplanner-toolbar_quaySelected', this.currentQuay);
		},
		searchingText() {
			clearTimeout(this.searchDelayTimer);
			this.searchDelayTimer = setTimeout(() => {
				this.$puiEvents.$emit('berthplanner-toolbar_searchingText', this.searchingText);
			}, 500);
		},
		showBerths1To26Bollards() {
			this.$puiEvents.$emit('berthplanner-toolbar_showBerths1To26Bollards', this.showBerths1To26Bollards);
		}
	},
	created() {
		this.fillCurrentDate();

		this.$puiEvents.$on('berthplanner-container_wrongBollards', (stops) => {
			this.stops = stops;
			this.stopsKey++;
		});
	},
	methods: {
		validDate() {
			return (
				((this.now.getDay() == new Date().getDay() && this.now.getMonth() == new Date().getMonth()) || this.now > new Date()) &&
				this.now.getUTCDate() < parseInt(new Date().getUTCDate() + 6)
			);
		},
		fillCurrentDate() {
			const dd = (this.now.getDate() < 10 ? '0' : '') + this.now.getDate();
			const MM = (this.now.getMonth() + 1 < 10 ? '0' : '') + (this.now.getMonth() + 1);
			const yyyy = this.now.getFullYear();
			this.currentDate = `${yyyy}-${MM}-${dd}`;
		},
		decrementDate() {
			this.now.setDate(this.now.getDate() - 1);
			this.fillCurrentDate();
		},
		incrementDate() {
			this.now.setDate(this.now.getDate() + 1);
			this.fillCurrentDate();
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		inputDatePicker() {
			this.menuDate = false;
			this.now = new Date(this.currentDate);
		},
		getColor(item) {
			if (item === 'not_registered') {
				return this.$store.state.berthplanner.unknownColor;
			}
			return this.$store.state.berthplanner[`${item}Color`];
		}
	}
};
</script>

<style lang="postcss">
.berthplanner-toolbar {
	min-height: 65px;

	& .v-input__control {
		min-height: 40px !important;
	}
	&__field {
		margin-right: 24px;
	}
}
</style>
